import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useNavigate } from "react-router-dom"
import { ApiService } from "../../Components/Services/apiservices"
import { useState, useEffect, useRef, useCallback } from "react"
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton"


function Agent() {
  const [featuredagents, setfeaturedAgents] = useState([])
  const [totalLength, setTotalLength] = useState("");
  const [imgUrl, setimgUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const didMountRef = useRef(true)
  const [hasMore, sethasMore] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (didMountRef.current) {
      getfeaturedAgents()
    }
    didMountRef.current = false
  }, [])




  const getfeaturedAgents = () => {
    setIsLoading(true)
    let pageNumber = Math.ceil(featuredagents.length / 12) + 1;
    ApiService.fetchData('agentsList' + "?page=" + pageNumber).then((res) => {
      if (res.status == 'success') {
        if (pageNumber >= res.agentData.last_page) {
          sethasMore(false);
        } else {
          sethasMore(true);
        }
        setimgUrl(res.agentImagePath)
        if (res.agentData.data.length > 0) {
          const apires = res.agentData.data;
          const mergeData = [...featuredagents, ...apires];
          setfeaturedAgents(mergeData);
          setTotalLength(res.agentData.total)
          setIsLoading(false)
        }
      }
      else {
        setIsLoading(false)
      }
    }).catch(() => {
      setIsLoading(false)
    })
  }


  return (
    <>
      <Header />
      <section className="flat-title-page">
        <div className="container">
          <h2 className="text-center">Our Agents</h2>
          <ul className="breadcrumb">
            <li><a href="/">Home</a></li>
            <li>/ Agent</li>
          </ul>
        </div>
      </section>
      <InfiniteScroll
        dataLength={featuredagents.length}
        next={getfeaturedAgents}
        hasMore={hasMore}
        // endMessage={<p className="text-center mb-5">No more data to load.</p>}
        style={{ overflow: "hidden !important" }}
      >
        {isLoading ? <> <section className="flat-section flat-agents">
            <div className="container">
              <div className="row wow " data-wow-delay=".4s" data-wow-duration="2000ms">
                {Array(4).fill().map((_, index) => (
                  <div className="box col-lg-3 col-sm-6" key={index}>
                    <div className="box-agent style-1 hover-img">
                      <a href="javascript:void(0)" className="box-img img-style">
                        <Skeleton height={300}  width={350}/>
                      </a>
                      <a href="javascript:void(0)" className="content">
                        <div className="info">
                          <h6 className="link"><Skeleton width={100} /></h6>
                          <p className="mt-4 text-variant-1"><Skeleton width={150} /></p>
                        </div>
                        <span ><Skeleton circle={true} width={30} height={30} /></span>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section></> : <>
         
          {featuredagents && featuredagents.length > 0 ? <>
            <section className="flat-section flat-agents">
              <div className="container">
                <div className="row wow fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
                  {featuredagents.map((item, index) => {
                    return (<>
                      <div className="box col-lg-3 col-sm-6" key={index}>
                        <div className="box-agent style-1 hover-img">
                          <a href={`/agent/${item.user_unique_id}`} className="box-img img-style">
                            <img src={item.user_logo ? item.user_logo : '/images/user.png'} alt="image-agent" />
                            {item.user_facebook || item.user_twitter || item.user_linkedin ? <>
                              <ul className="agent-social">
                                {item.user_facebook ? <>  <li><a href={item.user_facebook} target="_blank"><span className="icon icon-facebook"></span></a></li></> : ""}
                                {item.user_linkedin ? <>  <li><a href={item.user_linkedin} target="_blank"><span className="icon icon-linkedin"></span></a></li></> : ""}
                                {item.user_twitter ? <>  <li><a href={item.user_twitter} target="_blank"><span className="icon icon-twitter"></span></a></li></> : ""}
                                {item.user_instagram ? <>  <li><a href={item.user_twitter} target="_blank"><span className="icon icon-instagram"></span></a></li></> : ""}
                              </ul>
                            </> : ''}
                          </a>
                          <a href={`/agent/${item.user_unique_id}`} className="content">
                            <div className="info">
                              <h6 className="link">{item.user_name}</h6>
                              <p className="mt-4 text-variant-1">{item.user_designation}</p>
                            </div>
                            <a href={`tel:${item.user_mobile}`}><span className="icon-phone"></span></a>
                          </a>
                        </div>
                      </div>
                    </>)
                  })}
                </div>
              </div>
            </section>
          </> : null}
        </>}
      </InfiniteScroll>
      <Footer />
    </>

  )
}

export default Agent