import { useParams } from "react-router-dom"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { useState, useEffect, useRef, useContext, useCallback } from "react"
import { ApiService } from "../../Components/Services/apiservices"
import PropertySearch from "../PropertyList/property_search"
import ProjectBox from "../../Components/Element/project_box"
import Skeleton from 'react-loading-skeleton'
import InfiniteScroll from "react-infinite-scroll-component";


const PropertyList = () => {
    const didMountRef = useRef(true);
    const [projectList, setprojetctList] = useState([])
    const [totalLength, setTotalLength] = useState("");
    const [imgUrl, setimgUrl] = useState('')
    const [isLoading, setisLoading] = useState(false);
    const { slug } = useParams()
    useEffect(() => {
        if (didMountRef.current) {

            getProjectlist()

        }
        didMountRef.current = false;
    }, []);


    const getProjectlist = () => {
        setisLoading(true)
        let pageNumber = Math.ceil(projectList.length / 12) + 1;
        ApiService.fetchData("allProjectList"  + "?page=" + pageNumber).then((res) => {
            console.log(res , 'res')
            if (res.status == "success") {
              
                setimgUrl(res.project_img_url)
           
                if (res.resProjectData.data.length > 0) {
                    const apires = res.resProjectData.data;
                    const mergeData = [...projectList, ...apires];
                    setprojetctList(mergeData);
                    setTotalLength(res.resProjectData.total)
                    setisLoading(false)
        
                  } else {
                    setisLoading(false)
                  }
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        });
    };
    return (<>
        <Header></Header>
        <section className="flat-section-v6 flat-recommended flat-sidebar" data-wow-delay=".4s" data-wow-duration="2000ms">
            <div className="container">
                <div className="box-title-listing ">
                    <h5 className="mt-4">Project Listing</h5>
                    <div class="box-filter-tab">
                        <ul class="nav-tab-filter" role="tablist">
                            <li class="nav-tab-item" role="presentation">
                                <a href="#gridLayout" class="nav-link-item active" data-bs-toggle="tab" aria-selected="true" role="tab"><i class="icon icon-grid"></i></a>
                            </li>
                            <li class="nav-tab-item" role="presentation">
                                <a href="javascript:void(0)" class="nav-link-item" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab"><i class="icon icon-list"></i></a>
                            </li>
                        </ul>
                      
                        <div class="nice-select list-sort" tabindex="0"><span class="current">Sort by (Default)</span>
                            <ul class="list">
                                <li data-value="default" class="option selected">Sort by (Default)</li>
                                <li data-value="new" class="option">Newest</li>
                                <li data-value="old" class="option">Oldest</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <InfiniteScroll
                    dataLength={projectList.length}
                    next={getProjectlist}
                    hasMore={true}
                    // endMessage={<p>No more data to load.</p>}
                    style={{ overflow: "hidden !important" }}
                  >

                    {isLoading ? <>
                    <div className="row">
                        {[...Array(6)].map((__, index) => {
                            return (<>

                                <div className="col-xl-4 col-md-6" key={index}>
                                    <div className="homeya-box md" >
                                        <div className="archive-top">
                                            <div className="images-group">
                                                <div className="images-style" >
                                                    <Skeleton height={'250px'}></Skeleton>
                                                </div>
                                                <div className="top">
                                                    <ul className="d-flex gap-8 flex-column">
                                                        <li className=""><Skeleton width={'50px'}></Skeleton></li>

                                                    </ul>
                                                    <ul className="d-flex gap-4">
                                                        <li className="box-icon w-32" >
                                                            <a href="javascript:void(0)" data-bs-toggle="modal"> <Skeleton></Skeleton></a>

                                                        </li>
                                                        <li className="box-icon w-32">
                                                            <Skeleton></Skeleton>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className="content">
                                                <div className="text-1 text-capitalize">
                                                    <Skeleton width={'200px'}></Skeleton>
                                                </div>
                                                <p><Skeleton width={'250px'}></Skeleton></p>
                                                <div className="desc"><p><Skeleton  width={'300px'}></Skeleton></p> </div>


                                                <ul className="meta-list">
                                                    <li className="item">
                                                       
                                                        <span><Skeleton  width={'300px'}></Skeleton></span>
                                                    </li>
                                                </ul>


                                            </div>
                                        </div>
                                        <div className="archive-bottom d-flex justify-content-between align-items-center">


                                            <div className="d-flex align-items-center">
                                                <div className="h7 fw-7"><Skeleton width={'80px'}></Skeleton> </div>
                                                <div className="h7 fw-7 ms-2"><Skeleton width={'80px'}></Skeleton> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </> : <>
                    {projectList && projectList.length > 0 ? <>
                        <div className="row">
                            {projectList.map((item, index) => {
                                return (<>

                                    <div className="col-xl-4 col-md-6" key={index}>
                                        <ProjectBox propertydetail={item} imgUrl={imgUrl}></ProjectBox>

                                    </div>

                                </>)
                            })}
                        </div>
                    </> : <>
                    <div className="row">

                        <div className="col-12">
                            <div  className="d-flex justify-content-center">
                            <img src='/images/emptystate.gif' style={{width:'500px'}}></img>
                            </div>
                            <h6 className="text-center">No Project Found</h6>
                        </div>
                    </div>
                    </>}
                </>}
                    </InfiniteScroll>
              

            </div>
        </section>
        <Footer></Footer>
    </>)
}

export default PropertyList