import moment from 'moment'
import React from 'react'
import constant from '../../Components/Services/constant'

function BlogSidebar({latestBlogData,latestCategoryData,latestBlogImageUrl}) {
  return (
    <>
      <aside className="sidebar-blog fixed-sidebar">
        {/* <div className="widget-search">
          <div className="h7 fw-7 text-black">Search</div>
          <div className="search-box">
            <input className="search-field" type="text" placeholder="Search" />
            <a href="#" className="right-icon icon-search"></a>
          </div>
        </div> */}
        <div className="widget-box bg-surface recent">
          <div className="h7 fw-7 text-black">Recent Posts</div>
          <ul>
          {latestBlogData.map((value,index) => (
            <li key={index}>
              <a href={`/blog/${value.blog_slug}`} className="recent-post-item not-overlay hover-img">
                <div className="img-style">
                  <img src={value.blog_image != null? latestBlogImageUrl + "/" + value.blog_image: constant.DEFAULT_IMAGE} alt="post-recent" />
                </div>
                <div className="content">
                  <span className="subtitle">{moment(value.created_at).format('MMMM D, YYYY')}</span>
                  <div className="title">{value.blog_name}</div>
                </div>

              </a>
            </li>
      ))}
          </ul>

        </div>
        <div className="widget-box bg-surface categories">
          <div className="h7 fw-7 text-black">By Categories</div>
          <ul>
          {latestCategoryData.map((value,index) => (
            <li key={index}> <a href={`/blog/category/` + value.category_slug} className="categories-item "><span>{value.category_name}</span>{value.blog_count != 0 ? <span>({value.blog_count})</span> : ""}</a></li>
          ))}
            </ul>
        </div>


      </aside>
    </>
  )
}

export default BlogSidebar