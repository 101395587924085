import React, { useContext, useState } from 'react'
import DataContext from '../Element/context';
import { validEmail } from '../Element/Regex';
import { ApiService } from '../Services/apiservices';

function Footer() {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newsletterDetail, setNewsletterDetail] = useState({
    newsletter_email: ""
  })

  const contextValues = useContext(DataContext)
  const newsletterProcess = () => {
    setIsLoading(true);
    let counter = 0;
    const myElements = document.getElementsByClassName("newsletterRequired");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === '') {
        myElements[i].style.border = '1px solid red';
        counter++;
        setIsLoading(false);
      } else {
        myElements[i].style.border = '';
      }
    }
    if (counter === 0) {
      setErrorMessage("");
      if (!validEmail.test(newsletterDetail.newsletter_email)) {
        setErrorMessage("Please enter valid Email Id");
        setTimeout(() => {
          setErrorMessage('')
          // resetNewsletterForm()
          setIsLoading(false);
        }, [2000])

        return false;
      }
      ApiService.postData("newsletter", newsletterDetail).then((res) => {
        setIsLoading(false);
        if (res.status === "success") {
          setSuccessMessage(res.message);
          setErrorMessage("");
          setTimeout(() => {
            setSuccessMessage("");
            setNewsletterDetail({ newsletter_email: "" });
          }, 2000);
        } else {
          setErrorMessage(res.message);
          setSuccessMessage("");
          setTimeout(() => {
            setNewsletterDetail({ newsletter_email: "" });
            setErrorMessage("");
          }, 2000)
        }
      }).catch(() => {
        setIsLoading(false);
        setErrorMessage("An error occurred. Please try again.");
      });
    }
  };

  const onTodOChange = (e) => {
    const { name, value } = e.target;
    setNewsletterDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }))

    if (value !== '') {
      e.target.style.border = '';
    }
  };

  return (
    <>
      <footer className="footer">
        <div className="top-footer">
          <div className="container">
            <div className="content-footer-top">
              <div className="footer-logo">
                <a href='/'>
                <img src="/images/logow.png" alt="logo-footer" width="174" height="44" />
                </a>
              </div>
              <div className="wd-social">
                <span>Follow Us:</span>
                <ul className="list-social d-flex align-items-center">
                  {contextValues.settingData.facebook_url && (
                    <li><a href={contextValues.settingData.facebook_url} target='_blank' className="box-icon w-40 social"><i className="icon icon-facebook"></i></a>
                    </li>
                  )}
                  {contextValues.settingData.linked_url && (
                    <li><a href={contextValues.settingData.linked_url} target='_blank' className="box-icon w-40 social"><i className="icon icon-linkedin"></i></a></li>
                  )}
                  {contextValues.settingData.twitter_url && (
                    <li><a href={contextValues.settingData.twitter_url} className="box-icon w-40 social" target='_blank'>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_748_6323)">
                          <path d="M9.4893 6.77491L15.3176 0H13.9365L8.87577 5.88256L4.8338 0H0.171875L6.28412 8.89547L0.171875 16H1.55307L6.8973 9.78782L11.1659 16H15.8278L9.48896 6.77491H9.4893ZM7.59756 8.97384L6.97826 8.08805L2.05073 1.03974H4.17217L8.14874 6.72795L8.76804 7.61374L13.9371 15.0075H11.8157L7.59756 8.97418V8.97384Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_748_6323">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a></li>
                  )}
                  {contextValues.settingData.pinterest_url && (
                    <li><a href={contextValues.settingData.pinterest_url} target='_blank' className="box-icon w-40 social"><i className="icon icon-pinterest"></i></a></li>
                  )}
                  {contextValues.settingData.instagram_url && (
                    <li><a href={contextValues.settingData.instagram_url} target='_blank' className="box-icon w-40 social"><i className="icon icon-instagram"></i></a></li>
                  )}
                  {contextValues.settingData.youtube_url && (
                    <li><a href={contextValues.settingData.youtube_url} target='_blank' className="box-icon w-40 social"><i className="icon icon-youtube"></i></a></li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="inner-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-cl-1">

                  <p className="text-variant-2">Specializes in providing high-class tours for those in need. Contact Us</p>
                  <ul className="mt-12">
                    {contextValues.settingData.address && contextValues.settingData.address !== '' ? <>
                      <li className="mt-12 d-flex align-items-center gap-8">
                        <i className="icon icon-mapPinLine fs-20 text-variant-2"></i>
                        <p className="text-white">{contextValues.settingData.address}</p>
                      </li>

                    </> : ''}
                    {contextValues.settingData.admin_support_mobile && contextValues.settingData.admin_support_mobile !== '' ? <>
                      <li className="mt-12 d-flex align-items-center gap-8">
                        <i className="icon icon-phone2 fs-20 text-variant-2"></i>
                        <a  href={`tel:${contextValues.settingData.admin_support_mobile}`} className="text-white caption-1">{contextValues.settingData.admin_support_mobile}</a>
                      </li>


                    </> : ''}
                    {contextValues.settingData.admin_support_email && contextValues.settingData.admin_support_email !== '' ? <>
                      <li className="mt-12 d-flex align-items-center gap-8">
                        <i className="icon icon-mail fs-20 text-variant-2"></i>
                       
                        <a  className="text-white caption-1"  href={`mailto:${contextValues.settingData.admin_support_email}`}>
                          {contextValues.settingData.admin_support_email}
                          </a>
                          
                      </li>


                    </> : ''}

                  </ul>

                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-6">
                <div className="footer-cl-2">
                  <div className="fw-7 text-white">Quick Links</div>
                  <ul className="mt-10 navigation-menu-footer">
                    <li> <a href="/blog" className="caption-1 text-variant-2">News & Blogs</a></li>

                    <li> <a href="/faqs" className="caption-1 text-variant-2">FAQs</a></li>

                    <li> <a href="/about-us" className="caption-1 text-variant-2">About Us</a></li>

                    <li> <a href="/contact-us" className="caption-1 text-variant-2">Contact Us</a></li>

                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="footer-cl-3">
                  <div className="fw-7 text-white">Useful Links</div>
                  <ul className="mt-10 navigation-menu-footer">
                      <li> <a href="/privacy-policy" className="caption-1 text-variant-2">Privacy Policy</a></li>
                      <li> <a href="/terms-of-services" className="caption-1 text-variant-2">Terms & Conditions</a></li>
                      <li> <a href="/cookie-policy" className="caption-1 text-variant-2">Cookie Policy</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="footer-cl-4">
                  <div className="fw-7 text-white">
                    Newsletter
                  </div>
                  <p className="mt-12 text-variant-2">Your Weekly/Monthly Dose of Knowledge and Inspiration</p>
                  {successMessage && <div class="alert alert-success" role="alert">{successMessage}</div>}
                                        {errorMessage && <div class="alert alert-danger" role="alert">{errorMessage}</div>}

                  <form className="mt-12" id="subscribe-form" action="#" method="post" accept-charset="utf-8" data-mailchimp="true">
                    <div id="subscribe-content">
                      <span className="icon-left icon-mail"></span>
                      <input type="email" id="subscribe-email" placeholder="Your email address" className="newsletterRequired"
                        name='newsletter_email'
                        value={newsletterDetail.newsletter_email}
                        onChange={(e) => onTodOChange(e)} />
                      <button type="button"  className="button-subscribe" onClick={()=>{newsletterProcess()}}><i className="icon icon-send"></i></button>
                    </div>
                    <div id="subscribe-msg"></div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <div className="content-footer-bottom">
              <div className="copyright">©2024 Proopify. All Rights Reserved.</div>

              <ul className="menu-bottom">
                <li><a href="/page/terms-of-services">Terms Of Services</a> </li>
                <li><a href="/page/privacy-policy">Privacy Policy</a> </li>
                <li><a href="/page/cookie-policy">Cookie Policy</a> </li>

              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer