import { useEffect, useState } from "react";

const ProjectHeader = ({projectDetail, projectBaseUrl}) => {

    const closeMenu = () => {
        document.querySelector(".close-btn").click();
    }

    const [activeSection, setActiveSection] = useState('about');
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section');
            let currentSection = '';
            if(window.scrollY > 0){
                document.getElementById("header").classList.add('is-fixed');
            }else{
                document.getElementById("header").classList.remove('is-fixed');
            }
            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                if (window.scrollY >= sectionTop - 120) {
                    currentSection = section.getAttribute('id');
                }
            });
            setActiveSection(currentSection);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <header id="header" className="main-header fixed-header">
                <div className="header-lower">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-container d-flex justify-content-between align-items-center">
                                <div className="logo-box flex">
                                    <div className="logo projectHeaderlogo">
                                        <a href={`/${projectDetail.project_url}`}>
                                        {projectDetail && projectDetail.project_logo !== undefined && projectDetail.project_logo !== null && (
                                        <img src={projectDetail && projectDetail.project_logo !== undefined && projectDetail.project_logo !== null ?projectBaseUrl + projectDetail.project_logo:"/images/logo.png"}
                                        alt="logo" width="174" height="44" />
                                        )}
                                        </a>
                                    </div>
                                </div>
                                <div className="nav-outer flex align-center">
                                    <nav className="main-menu show navbar-expand-md">
                                        <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                            <ul className="navigation clearfix">
                                                <li className={`home ${activeSection === 'about' ? 'current' : ''}`}><a href="#about" onClick={closeMenu}>About</a></li>
                                                <li className={`home ${activeSection === 'location' ? 'current' : ''}`}><a href="#location">Locations</a></li>
                                                <li className={`home ${activeSection === 'floor' ? 'current' : ''}`}><a href="#floor">Floor Plan</a></li>
                                                <li className={`home ${activeSection === 'amenities' ? 'current' : ''}`}><a href="#amenities">Amenities & specification</a></li>
                                                <li className={`home ${activeSection === 'brochure' ? 'current' : ''}`}><a href="#modalheaderContact" data-bs-toggle="modal">E-Brochure</a></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className="header-account">
                                    <div className="flat-bt-top">
                                        <a className="tf-btn primary size-3" href="#contact">Contact Now</a>
                                    </div>
                                </div>

                                <div className="mobile-nav-toggler mobile-button"><span></span></div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="close-btn"><span className="icon flaticon-cancel-1"></span></div>
                <div className="mobile-menu">
                    <div className="menu-backdrop"></div>
                    <nav className="menu-box">
                        <div className="nav-logo"><a href="/">
                        {projectDetail && projectDetail.project_logo !== undefined && projectDetail.project_logo !== null && (
                                        <img src={projectDetail && projectDetail.project_logo !== undefined && projectDetail.project_logo !== null ?projectBaseUrl + projectDetail.project_logo:"/images/logo.png"}
                                        alt="logo" width="174" height="44" />
                                        )}
                        </a></div>
                        <div className="bottom-canvas">
                            <div className="" style={{borderBottom: "1px solid #e4e4e4", paddingBottom: "20px", marginBottom: "20px"}}>
                                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                    <ul className="navigation clearfix">
                                        <li className={`home ${activeSection === 'about' ? 'current' : ''}`}><a href="#about" onClick={closeMenu}>About</a></li>
                                        <li className={`home ${activeSection === 'location' ? 'current' : ''}`}><a href="#location" onClick={closeMenu}>Locations</a></li>
                                        <li className={`home ${activeSection === 'floor' ? 'current' : ''}`}><a href="#floor" onClick={closeMenu}>Floor Plan</a></li>
                                        <li className={`home ${activeSection === 'amenities' ? 'current' : ''}`}><a href="#amenities" onClick={closeMenu}>Amenities & specification</a></li>
                                        <li className={`home ${activeSection === 'brochure' ? 'current' : ''}`}><a href="#modalheaderContact" data-bs-toggle="modal" onClick={closeMenu}>E-Brochure</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="button-mobi-sell">
                                <a className="tf-btn primary" href="#contact" onClick={closeMenu}>Contact Us</a>
                            </div>
                            <div className="mobi-icon-box d-none">
                                <div className="box d-flex align-items-center">
                                    <span className="icon icon-phone2"></span>
                                    <div>1-333-345-6868</div>
                                </div>
                                <div className="box d-flex align-items-center d-none">
                                    <span className="icon icon-mail"></span>
                                    <div>themesflat@gmail.com</div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
}

export default ProjectHeader;