import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices";
import InfiniteScroll from "react-infinite-scroll-component";


const Dashboard=()=>{
    const didMountRef = useRef(true)  
    const navigate = useNavigate()
  
    const [myproperties, setmyproperties] = useState([])
    const [dashBoardCount, setdashBoardCount] = useState({propertycount:0, userleads:0,userfavpropcount:0,userfavprojcount:0})
    const [property_img_url, setproperty_img_url] = useState(null);
    const [hasMore, setHasmore] = useState(false)
    useEffect(() => {
      if (didMountRef.current) {
  
        getMyPropertyData()
      }
      didMountRef.current = false
    })

  
    const getMyPropertyData = () => {
      let pageNumber = Math.ceil(myproperties.length / 12) + 1;
      ApiService.fetchData('userProperties?page=' + pageNumber).then((res) => {
        if (res.status == 'success') {
          if(res.last_page > 1 && res.last_page != pageNumber){
            setHasmore(true)
          }else{
            setHasmore(false)
          }
          setproperty_img_url(res.property_img_url)
          setmyproperties(res.data.data)
          setdashBoardCount({userfavprojcount:res.favProjectCount, userfavpropcount:res.favPropertyCount,userleads:res.leadsEnquiryCount,propertycount:res.userPropertyCount})
        }
        else if (res.status == 'session_expired' && res.message == 'Session expired') {
          localStorage.removeItem('USER_TOKEN')
          navigate('/')
        }
  
      }).catch((error) => {
  
      })
    };

    return(<>
   
    <div id="wrapper">
        <div id="page" class="clearfix">
            <div class="layout-wrap">
                <Header></Header>
            
                <div class="main-content">
                    <div class="main-content-inner">
                      
                        <div class="flat-counter-v2 tf-counter">
                            <div class="counter-box">
                                <div class="box-icon w-68 round">
                                    <span class="icon icon-list-dashes"></span>
                                </div>
                                <div class="content-box">
                                    <div class="title-count">your Listing</div>
                                    <div class="d-flex align-items-end">
                                        <h6 class="number" data-speed="2000" data-to="17" data-inviewport="yes">{dashBoardCount.propertycount}</h6>                                   
                                        {/* <span class="fw-7 text-variant-2">{dashBoardCount.propertycount}</span> */}
                                    </div>                              

                                </div>
                            </div>
                            <div class="counter-box" onClick={()=>{navigate('/leads')}}>
                                <div class="box-icon w-68 round">
                                    <span class="icon icon-clock-countdown"></span>
                                </div>
                                <div class="content-box">
                                    <div class="title-count">Leads/Enquiries</div>
                                    <div class="d-flex align-items-end">
                                        <h6 class="number" data-speed="2000" data-to="0" data-inviewport="yes">{dashBoardCount.userleads}</h6>                                   
                                    </div>                              

                                </div>
                            </div>
                            <div class="counter-box" onClick={()=>{navigate('/favourite-properties')}}>
                                <div class="box-icon w-68 round">
                                    <span class="icon icon-bookmark"></span>
                                </div>
                                <div class="content-box">
                                    <div class="title-count">Favorite Properties</div>
                                    <div class="d-flex align-items-end">
                                        <h6 class="number" data-speed="2000" data-to="1" data-inviewport="yes">{dashBoardCount.userfavpropcount}</h6>                                   
                                    </div>                              

                                </div>
                            </div>
                            <div class="counter-box" onClick={()=>{navigate('/favourite-projects')}}>
                                <div class="box-icon w-68 round">
                                    <span class="icon icon-review"></span>
                                </div>
                                <div class="content-box">
                                    <div class="title-count">Favorite Projects</div>
                                    <div class="d-flex align-items-end">
                                        <h6 class="number" data-speed="2000" data-to="17" data-inviewport="yes">{dashBoardCount.userfavprojcount}</h6>                                   
                                    </div>                              

                                </div>
                            </div>
                        </div>
                        <div class="wrapper-content row">
                            <div class="col-xl-12">
                                <div class="widget-box-2 wd-listing">
                                    <h6 class="title">New Listing</h6>
                                    <div class="wd-filter">
                                        <div class="ip-group">
                                            <input type="text" placeholder="Search"/>
                                        </div>
                                        <div class="ip-group icon">
                                            <input type="text" id="datepicker1" class="ip-datepicker icon" placeholder="From Date"/>
                                        </div>
                                        <div class="ip-group icon">
                                            <input type="text" id="datepicker2" class="ip-datepicker icon" placeholder="To Date"/>
                                        </div>
                                        <div class="ip-group">
                                            <div class="nice-select" tabindex="0"><span class="current">Select</span>
                                                <ul class="list"> 
                                                    <li data-value="1" class="option selected">Select</li>
                                                    <li data-value="2" class="option">Today</li>
                                                    <li data-value="3" class="option">Yesterday</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex gap-4"><span class="text-primary fw-7">17</span><span class="text-variant-1">Results found</span></div>
                                    <div className="wrap-table">
                                <div className="table-responsive">
                                    <InfiniteScroll
                                    dataLength={myproperties.length}
                                    next={getMyPropertyData}
                                    hasMore={hasMore}
                                    loader={<h4>Loading...</h4>}
                                    style={{ overflow: "hidden !important" }}
                                    >
                                        
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Date Published</th>
                                                    <th>Status</th>
                                                    <th>Feature</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {myproperties.length > 0 && (
                                                    myproperties.map((property, key) => {
                                                        let date1 =new Date(property.created_at).toDateString()

                                                        return <tr className="file-delete">
                                                                    <td>
                                                                        <div className="listing-box">
                                                                            <div className="images">
                                                                                <img src={property_img_url + property.property_image} alt="images" />
                                                                            </div>
                                                                            <div className="content">
                                                                                <div className="title"><a href={"property/"+property.property_slug + "/" + property.property_url_id} className="link">{property.property_name}</a> </div>
                                                                                <div className="text-date">{property.property_address}</div>
                                                                                <div className="text-1 fw-7">{"₹"+property.property_expected_price}</div> 
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span>{date1}</span>
                                                                    </td>
                                                                    <td>
                                                                        <div className="status-wrap">
                                                                            {(property.property_status==1)?<a href="#" className="btn-status">Published</a>:<a href="#" className="btn-status">Under Review</a>}
                                                                            
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span>{(property.property_featured == 1)?"Yes":"No"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-action">
                                                                            <li><a className="item"><i className="icon icon-edit"></i>Edit</a></li>
                                                                            <li><a className="item"><i className="icon icon-sold"></i>Sold</a></li>
                                                                            <li><a className="remove-file item"><i className="icon icon-trash"></i>Delete</a></li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                    })
                                                )}
                                                
                                            </tbody>
                                        </table>


                                    </InfiniteScroll>
                                </div>
                            </div>
                            
                                </div>
                             
                            </div>
                            {/* <div class="col-xl-3">
                                <div class="widget-box-3 mess-box">
                                    <h6>Messages</h6>
                                    <span class="text-variant-1">No message</span>
                                </div>
                                <div class="widget-box-3 recent-box">
                                    <h6>Recent Reviews</h6>
                                    <div class="box-tes-item">
                                        <div class="box-avt d-flex align-items-center gap-12">
                                            <div class="avatar avt-40 round">
                                                <img src="images/avatar/avt-2.jpg" alt="avatar"/>
                                            </div>
                                            <p>February 18, 2024</p>
                                        </div>
                                        <p class="note p-16">
                                            The housing sector has long been a focal point for investors seeking stability and growth. Understanding the dynamics of housing stocks and effectively trading within this sector can lead to substantial gains.
                                        </p>
                                        <ul class="list-star">
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                        </ul>
                                        
                                        
                                    </div>
                                    <div class="box-tes-item">
                                        <div class="box-avt d-flex align-items-center gap-12">
                                            <div class="avatar avt-40 round">
                                                <img src="images/avatar/avt-2.jpg" alt="avatar"/>
                                            </div>
                                            <p>February 18, 2024</p>
                                        </div>
                                        <p class="note p-16">
                                            The housing sector has long been a focal point for investors seeking stability and growth. Understanding the dynamics of housing stocks and effectively trading within this sector can lead to substantial gains.
                                        </p>
                                        <ul class="list-star">
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                        </ul>
                                        
                                        
                                    </div>
                                    <div class="box-tes-item">
                                        <div class="box-avt d-flex align-items-center gap-12">
                                            <div class="avatar avt-40 round">
                                                <img src="images/avatar/avt-2.jpg" alt="avatar"/>
                                            </div>
                                            <p>February 18, 2024</p>
                                        </div>
                                        <p class="note p-16">
                                            The housing sector has long been a focal point for investors seeking stability and growth. Understanding the dynamics of housing stocks and effectively trading within this sector can lead to substantial gains.
                                        </p>
                                        <ul class="list-star">
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                        </ul>
                                        
                                        
                                    </div>
                                    <div class="box-tes-item">
                                        <div class="box-avt d-flex align-items-center gap-12">
                                            <div class="avatar avt-40 round">
                                                <img src="images/avatar/avt-2.jpg" alt="avatar"/>
                                            </div>
                                            <p>February 18, 2024</p>
                                        </div>
                                        <p class="note p-16">
                                            The housing sector has long been a focal point for investors seeking stability and growth. Understanding the dynamics of housing stocks and effectively trading within this sector can lead to substantial gains.
                                        </p>
                                        <ul class="list-star">
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                            <li class="icon icon-star"></li>
                                        </ul>
                                        
                                        
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                  
                </div>
                    <Footer></Footer>
            </div>
        </div>
    </div>
    </>)
}

export default Dashboard