import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useRef, useCallback } from "react"
import { ApiService } from '../../Components/Services/apiservices'
import constant from '../../Components/Services/constant';
import InfiniteScroll from "react-infinite-scroll-component";
import QueryModal from '../../Components/Element/Modals/vendor_query_modal';

function ServiceDetail() {
    const { slug } = useParams()
    const [ServiceDetail, setServiceDetail] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [stateDropdown, setstateDropdown] = useState([])
    const [vendorList, setvendorList] = useState([])
    const [cityDropdown, setcityDropdown] = useState([])
    const [areaDropdown, setareaDropdown] = useState([])
    const [vendorId, setvendorId] = useState('')
    const [searchVendor, setsearchVendor] = useState({
        vendor_area_id: "",
        vendor_state_id: "",
        vendor_city_id: "",
        vendor_service_id: "",
    });
    const [childData, setChildData] = useState('');
    const [searchcityquery, setsearchcityquery] = useState('');
    const [searchstatequery, setsearchstatequery] = useState('');
    const [searchareaquery, setsearchareaquery] = useState('');
    const [imgUrl, setimgUrl] = useState('')
    const [showVendorList, setshowVendorList] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getServiceDetail()
        }
        didMountRef.current = false
    }, [])

    const onTodoChange = (name, value) => {
        setsearchVendor((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name == 'vendor_state_id') {
            if (value) {
                setsearchcityquery('')
            }
        }
        if (name == 'vendor_city_id') {
            if (value) {

                setsearchareaquery('')
            }
        }
    };
    const onChangeCitySerachInput = (value) => {
        setsearchcityquery(value);
        if (value.length > 1) {
            const dataString = {
                cities_name: value,
                state_id: searchVendor.vendor_state_id
            }
            ApiService.postData('getSearchedCityData', dataString).then((res) => {
                if (res.status === 'success') {
                    setcityDropdown(res.data);
                }
            }).catch((error) => {
                console.error("Error fetching city data:", error);
            });
        } else {
            setcityDropdown([]);
        }
    }
    const onChangeStateSerachInput = (value) => {
        setsearchstatequery(value);
        if (value.length > 1) {
            const dataString = {
                state_name: value,
            }
            ApiService.postData('getSearchedstateData', dataString).then((res) => {
                if (res.status === 'success') {
                    setstateDropdown(res.data);
                }
            }).catch((error) => {
                console.error("Error fetching city data:", error);
            });
        } else {
            setstateDropdown([]);
        }
    }
    const onChangeAreaSerachInput = (value) => {
        setsearchareaquery(value);
        if (value.length > 1) {
            const dataString = {
                area_name: value,
                state_id: searchVendor.vendor_state_id,
                city_id: searchVendor.vendor_city_id
            }
            ApiService.postData('getSearchedAreaData', dataString).then((res) => {
                if (res.status === 'success') {
                    setareaDropdown(res.data);
                }
            }).catch((error) => {
                console.error("Error fetching city data:", error);
            });
        } else {
            setareaDropdown([]);
        }
    }
    const getServiceDetail = () => {
        setIsLoading(true)
        const dataString = {
            service_slug: slug
        }
        ApiService.postData('serviceDetails', dataString).then((res) => {
            if (res.status == 'success') {
                setimgUrl(res.service_img_url)
                setServiceDetail(res.serviceData)
                setsearchVendor((prevState) => ({
                    ...prevState,
                    ['vendor_service_id']: res.serviceData.service_id,
                }));
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }

    const onHandleSearch = (e) => {
        e.preventDefault()
        if (searchVendor.vendor_state_id == '') {
            setErrorMessage('Please select the state')
            return
        }
        if (searchVendor.vendor_city_id == '') {
            setErrorMessage('Please select the city')
            return
        }
        ApiService.postData('serviceareavendor', searchVendor).then((res) => {

            if (res.status == 'success') {
                setshowVendorList(true)
                setvendorList(res.vendorData);
                setIsLoading(false)
                // setsearchareaquery('')
                // setsearchcityquery('')
                // setsearchstatequery('')
                // setsearchVendor({
                //     vendor_area_id: "",
                //     vendor_state_id: "",
                //     vendor_city_id: "",
                //     vendor_service_id: "",
                // })

            }
            else{
                setIsLoading(false)
                setErrorMessage(res.message)
            }
        }).catch(()=>{
           setIsLoading(false)
        })

    }
    const handleDataFromChild = (data) => {
        setChildData(data);
    };

    const openQueryModal = (item) => {
        const modalElement = new window.bootstrap.Modal(document.getElementById('modalQuery'));
        modalElement.show();
        setvendorId(item.vendor_id)
    };
    const handleCitySelect = (e, selectedcity) => {
        e.preventDefault()
        onTodoChange('vendor_city_id', selectedcity.cities_id)
        setsearchcityquery(selectedcity.cities_name)
        setcityDropdown([])

    }
    const handleStateSelect = (e, selectedstate) => {
        e.preventDefault()
        onTodoChange('vendor_state_id', selectedstate.state_id)
        setsearchstatequery(selectedstate.state_name)
        setstateDropdown([])

    }
    const handleAreaSelect = (e, selectedarea) => {
        e.preventDefault()
        onTodoChange('vendor_area_id', selectedarea.pin_id)
        setsearchareaquery(selectedarea.PostOfficeName)
        setareaDropdown([])

    }




    return (
        <>
            <Header />
            <section className="flat-section-v6 flat-recommended flat-sidebar" data-wow-delay=".4s" data-wow-duration="2000ms">
                <div className='container'>
                    <div className="row d-flex justify-content-center">
                        <div class="col-md-12">
                            <div class="homeya-box">
                                <div class="archive-top">
                                    <a href="javascript:void(0)" class="images-group">
                                        <div class="images-style">
                                            <img src={ServiceDetail.service_image ? imgUrl + ServiceDetail.service_image : constant.DEFAULT_IMAGE} alt={ServiceDetail.service_name} />
                                        </div>
                                    </a>
                                    <div class="content">
                                        <div class="h7 text-capitalize fw-7"><a href="javascript:void(0)" class="link">{ServiceDetail.service_name}</a></div>
                                    </div>
                                </div>
                                {ServiceDetail.service_desc ? <div class="archive-bottom  align-items-center">
                                    <div dangerouslySetInnerHTML={{ __html: ServiceDetail.service_desc }}></div>
                                </div> : ''}

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section class="flat-section flat-contact">
                <div class="container">
                    <div class="row  d-flex justify-content-center">
                        <div class="col-lg-12">
                            <div class="contact-content">
                                <h5 className='text-center'>Search for the Vendors</h5>
                                <p class="body-2 text-variant-1 text-center">Browse our list of trusted vendors offering a wide range of services to meet your needs. Connect directly with the best service providers in the industry.</p>
                                <form class="form-contact">
                                    {errorMessage && (
                                        <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    )}
                                    <div class="box grid-4">
                                        <div className="form-group-2 form-style">
                                            <label> Province/State:</label>
                                            <div className="group-ip">
                                                <input type="text" className="form-control" placeholder="Search State" title="Search for" value={searchstatequery} onChange={(e) => { onChangeStateSerachInput(e.target.value) }} />

                                            </div>
                                            {stateDropdown && stateDropdown.length > 0 ? <>
                                                <ul className='searchlocationlist'>
                                                    {stateDropdown.map((item, index) => {
                                                        return (<>
                                                            <li key={index} onClick={(e) => { handleStateSelect(e, item) }}>{item.state_name}</li>
                                                        </>)
                                                    })}

                                                </ul>


                                            </> : ''}
                                        </div>
                                        <div className="form-group-2 form-style">
                                            <label>City</label>
                                            <div className="group-ip">
                                                <input type="text" className="form-control" placeholder="Search City" title="Search for" value={searchcityquery} onChange={(e) => { onChangeCitySerachInput(e.target.value) }} />

                                            </div>
                                            {cityDropdown && cityDropdown.length > 0 ? <>
                                                <ul className='searchlocationlist'>
                                                    {cityDropdown.map((item, index) => {
                                                        return (<>
                                                            <li key={index} onClick={(e) => { handleCitySelect(e, item) }}>{item.cities_name}</li>
                                                        </>)
                                                    })}

                                                </ul>


                                            </> : ''}
                                        </div>

                                        <div className="form-group-2 form-style">
                                            <label> Neighborhood/Area:</label>
                                            <div className="group-ip">
                                                <input type="text" className="form-control" placeholder="Search Area" title="Search for" value={searchareaquery} onChange={(e) => { onChangeAreaSerachInput(e.target.value) }} />

                                            </div>
                                            {areaDropdown && areaDropdown.length > 0 ? <>
                                                <ul className='searchlocationlist'>
                                                    {areaDropdown.map((item, index) => {
                                                        return (<>
                                                            <li key={index} onClick={(e) => { handleAreaSelect(e, item) }}>{item.PostOfficeName}</li>
                                                        </>)
                                                    })}

                                                </ul>


                                            </> : ''}
                                        </div>

                                       
                                        <div class="send-wrap" style={{ marginTop: '30px' }}><button class="tf-btn primary size-1 mt-5" onClick={(e) => { onHandleSearch(e) }}>Search</button></div>
                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
            {showVendorList && (
                <div className="layout-wrap">
                    <div className="main-content">
                        <div className="main-content-inner">
                            <div className="wrapper-content row">
                                <div className="col-xl-12">
                                    <div className="widget-box-2 wd-listing">
                                        <div className="wrap-table">
                                            <div className="table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Mobile</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {vendorList.length > 0 ? (
                                                            <>
                                                                {vendorList.length > 0 && (
                                                                    vendorList.map((data, key) => {
                                                                        return <tr className={"row_"} key={key}>

                                                                            <td>
                                                                                <span>{data.vendor_name}</span>
                                                                            </td>
                                                                            {childData==data.vendor_id || data.vendor_enquiry_status==1 ? <td >

                                                                                <span>{data.vendor_mobile}</span>
                                                                            </td> : <td onClick={() => { openQueryModal(data) }}>

                                                                                <span>{`XXX-XXXX-${data.vendor_mobile.slice(-3)}`}</span>
                                                                            </td>}

                                                                        </tr>
                                                                    })
                                                                )}
                                                            </>
                                                        ) : (
                                                            <><tr>
                                                                <td colSpan={3}>No Vendor Found!</td>
                                                            </tr></>
                                                        )}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )}
            <Footer />

            <QueryModal vendorId={vendorId} onSendData={handleDataFromChild}></QueryModal>

        </>
    )
}

export default ServiceDetail