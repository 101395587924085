import { useState } from 'react';
import { ApiService } from '../../Services/apiservices';
import { validNumber, validEmail } from '../Regex';

function PropertyQueryModal({ property_owner_id,property_id, onSendData }) {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [enquiryDetails, setEnquiryDetails] = useState({
        user_name: "",
        user_email: "",
        user_mobile: '',
        user_message: ''
    });

    const onEnquireInputChange = (e) => {
        setErrorMessage('');
        setSuccessMessage('');
        const { name, value } = e.target;
        setEnquiryDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };

    const userEnquiryProcess = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        let counter = 0;
        const myElements = document.getElementsByClassName('queryrequired');
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter === 0) {
            if (!validEmail.test(enquiryDetails.user_email)) {
                setErrorMessage("Please Enter Valid Email");
                return false;
            }
            if (!validNumber.test(enquiryDetails.user_mobile)) {
                setErrorMessage("Please Enter Valid Mobile Number");
                return false;
            }

            setIsLoading(true);

            const dataString = {
                enquiry_name: enquiryDetails.user_name,
                enquiry_email: enquiryDetails.user_email,
                enquiry_mobile: enquiryDetails.user_mobile,
                enquiry_message: enquiryDetails.user_message,
                enquiry_builder_id: property_owner_id,
                enquiry_property_id:property_id
            };

            ApiService.postData("propertyEnquiryProcess", dataString).then((res) => {
                setIsLoading(false);
                if (res.status === "success") {
                    setSuccessMessage(res.message);
                    onSendData(true);
                    // Close the modal
                    const modalElement = document.getElementById('propertymodalQuery');
                    const modal = window.bootstrap.Modal.getInstance(modalElement); // Get existing instance
                    if (modal) {
                        modal.hide();
                    } else {
                        // If no instance found, create a new one
                        new window.bootstrap.Modal(modalElement).hide();
                    }
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 1000);
                } else {
                    setErrorMessage(res.message);
                }
            }).catch(() => {
                setIsLoading(false);
            });
        }
    };

    return (
        <div className="modal fade" id="propertymodalQuery">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="flat-account bg-surface">
                        <span className="close-modal icon-close2" data-bs-dismiss="modal" aria-label="Close"></span>
                        <h6 className="title text-center">Property Enquiry</h6>
                        <p className='text-center'>Get in touch  to explore the properties.</p>
                        <div className='mt-10'>
                            {errorMessage && (
                                <div className="alert alert-danger" role="alert">
                                    {errorMessage}
                                </div>
                            )}
                            {successMessage && (
                                <div className="alert alert-success" role="alert">
                                    {successMessage}
                                </div>
                            )}
                            <form onSubmit={userEnquiryProcess}>
                                <div className='form-group mb-15'>
                                    <input type='text' placeholder='Full Name' className='queryrequired' name="user_name" onChange={onEnquireInputChange} />
                                </div>
                                <div className='form-group mb-15'>
                                    <input type='email' placeholder='Email Address' className='queryrequired' name='user_email' onChange={onEnquireInputChange} />
                                </div>
                                <div className='form-group mb-15'>
                                    <input type='number' min={0} placeholder='Mobile Number' className='queryrequired' name='user_mobile' onChange={onEnquireInputChange} />
                                </div>
                                <div className='form-group mb-15'>
                                    <textarea type='text' min={0} placeholder='Message' name='user_message' onChange={onEnquireInputChange}></textarea>
                                </div>
                                <div className='mb-15'>
                                    <button className='tf-btn primary w-100 mb-15' type='submit' disabled={isLoading}>
                                        {isLoading ? (<img src="/images/loder01.gif" width="60px" height="11px" alt="Loading" />) : ("Submit")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropertyQueryModal;
