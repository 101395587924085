
const ProjectNearBy = ({ projectDetail }) => {

    return <div className="single-property-element single-property-nearby">
        <div className="grid-2 box-nearby">
        {projectDetail?.project_schools && projectDetail?.project_schools!='' && (
            <div className="mb-4">
            <h6 className="tx-18">Schools</h6>
            
                 <ul className="box-left">
                    {projectDetail.project_schools.map((item, index) => (
                        <li className="item-nearby" key={index} dangerouslySetInnerHTML={{ __html: item }} ></li>
                    ))}
                     
                </ul>
       </div>
  )}
   {projectDetail?.project_shoppings && projectDetail?.project_shoppings!='' && (
            <div className="mb-4">
            <h6 className="tx-18">Shoppings</h6>
            
                 <ul className="box-left">
                 {projectDetail.project_shoppings.map((item, index) => (
                        <li className="item-nearby" key={index} dangerouslySetInnerHTML={{ __html: item }} ></li>
                    ))}
                     
                </ul>
       </div>
  )}

{projectDetail?.project_hospitals && projectDetail?.project_hospitals!='' && (
               <div className="mb-4">
                    <h6 className="tx-18">Hospitals</h6>
                    <ul className="box-left">
                    {projectDetail.project_hospitals.map((item, index) => (
                        <li className="item-nearby" key={index} dangerouslySetInnerHTML={{ __html: item }} ></li>
                    ))}
                     </ul>
                </div>
            )}

{projectDetail?.project_connectivity && projectDetail?.project_connectivity!='' && (
                 <div className="mb-4">
                    <h6 className="tx-18">Connectivity</h6>
                    <ul className="box-left">
                    {projectDetail.project_connectivity.map((item, index) => (
                        <li className="item-nearby" key={index} dangerouslySetInnerHTML={{ __html: item }} ></li>
                    ))}
                     </ul>
                </div>
            )}

        </div>
           
           
            
            
        </div>
}

export default ProjectNearBy;