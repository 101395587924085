import { ApiService } from "../../Components/Services/apiservices"
import React from 'react';
import { useState, useEffect, useRef, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import PropertyBox from "../../Components/Element/property_box";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import {isMobile} from 'react-device-detect';

const FeaturedProperties = () => {
    const [featuredpropertiesdata, setfeaturedPropertiesdata] = useState([])
    const [imgUrl, setimgUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedproperty()
        }
        didMountRef.current = false
    }, [])

    const getfeaturedproperty = () => {
        setIsLoading(true)
        ApiService.fetchData('featuredpropertylist ').then((res) => {
            if (res.status == 'success') {
                setimgUrl(res.property_img_url)
                setfeaturedPropertiesdata(res.resPropertyData)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500)
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }
    const sliderfeaturedRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!sliderfeaturedRef.current) return;
        sliderfeaturedRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderfeaturedRef.current) return;
        sliderfeaturedRef.current.swiper.slideNext();
    }, [])
    const handleClick = (route) => {
        localStorage.removeItem('type')
        window.location.href = route
    };

    return (<>
        {featuredpropertiesdata && featuredpropertiesdata.length > 0 ? <>

            <section className="flat-section flat-recommended pt-0 wow fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
                <div className="container">
                    <div className="box-title style-2 text-center">
                        <div className="text-subtitle text-primary">Featured Properties</div>
                        <h4 className="mt-4">Discover Proopify's Finest Properties for Your Dream Home</h4>
                    </div>
                    <div>
                        <Swiper
                            spaceBetween={15}
                            slidesPerView={4}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                480: {
                                    slidesPerView: 1,
                                },
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 1,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                                1280: {
                                    slidesPerView: 4,
                                },
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            ref={sliderfeaturedRef}
                        >
                            {featuredpropertiesdata?.map((item, index) => {
                                return (<>

                                    <SwiperSlide key={index}>
                                        <PropertyBox propertydetail={item} imgUrl={imgUrl}></PropertyBox>
                                    </SwiperSlide>
                                </>)
                            })}
                        </Swiper>
                        {isMobile && featuredpropertiesdata.length > 2 && (
                            <div className="box-navigation">
                                <div className="navigation style-1 swiper-nav-next nav-next-category" onClick={handlePrev}><span className="icon icon-arr-l"></span></div>
                                <div className="navigation style-1 swiper-nav-prev nav-prev-category" onClick={handleNext} ><span className="icon icon-arr-r"></span></div>
                            </div>
                        )}
                        {!isMobile && featuredpropertiesdata.length > 4 && (
                            <div className="box-navigation">
                                <div className="navigation style-1 swiper-nav-next nav-next-category" onClick={handlePrev}><span className="icon icon-arr-l"></span></div>
                                <div className="navigation style-1 swiper-nav-prev nav-prev-category" onClick={handleNext} ><span className="icon icon-arr-r"></span></div>
                            </div>
                        )}

                    </div>

                    <div className="text-center">
                        <a href="/property" className="tf-btn primary size-1">View All Properties</a>
                    </div>
                </div>
            </section>
        </> : ''}


    </>)
}

export default FeaturedProperties