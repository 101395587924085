import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices";
import InfiniteScroll from "react-infinite-scroll-component";


const FavouritesProjects=()=>{
    const didMountRef = useRef(true)  
    const navigate = useNavigate()
  
    const [myproperties, setmyproperties] = useState([])
    const [project_img_url, setproject_img_url] = useState(null);
    const [hasMore, setHasmore] = useState(false)
    useEffect(() => {
      if (didMountRef.current) {
  
        getMyPropertyData()
      }
      didMountRef.current = false
    })

    const addtofav = (project) => {    
        if (window.confirm("Are you sure?")) {    
            const dataString = {
                project: project.project_id,
            };
            ApiService.postData("addtofav", dataString).then((res) => {
                if (res.data.status == "success") {
                    let deleteRow = document.getElementsByClassName('row_' + project.project_id)[0];
                    if(deleteRow.parentNode)
                        deleteRow.parentNode.removeChild(deleteRow);
                } else {
                    
                }
            }).catch(() => {
                
            });
        }
    };
  
  
    const getMyPropertyData = () => {
      let pageNumber = Math.ceil(myproperties.length / 12) + 1;
      ApiService.fetchData('favprojectlist?page=' + pageNumber).then((res) => {
        if (res.status == 'success') {
          if(res.last_page > 1 && res.last_page != pageNumber){
            setHasmore(true)
          }else{
            setHasmore(false)
          }
          setproject_img_url(res.project_img_url)
          setmyproperties(res.favprojectlist.data)
        }
        else if (res.status == 'session_expired' && res.message == 'Session expired') {
          localStorage.removeItem('USER_TOKEN')
          navigate('/')
        }
  
      }).catch((error) => {
  
      })
    };


    return(<>
    <div className="layout-wrap">
        <Header></Header>
        <div className="main-content">
            <div className="main-content-inner">
                <div className="wrapper-content row">
                    <div className="col-xl-12">
                        <div className="widget-box-2 wd-listing">
                            <h6 className="title">My Favorite Projects</h6>
                            <div className="wrap-table">
                                <div className="table-responsive">
                                    <InfiniteScroll
                                    dataLength={myproperties.length}
                                    next={getMyPropertyData}
                                    hasMore={hasMore}
                                    loader={<h4>Loading...</h4>}
                                    style={{ overflow: "hidden !important" }}
                                    >
                                        
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Date Published</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                
                                                {myproperties.length > 0 ? (
                                                    <>
                                                    {myproperties.length > 0 && (
                                                            myproperties.map((data, key) => {
                                                                let project = data.project;
                                                                let date1 =new Date(project.created_at).toDateString()

                                                                return <tr className={"row_"  + project.project_id}>
                                                                            <td>
                                                                                <div className="listing-box">
                                                                                    <div className="images">
                                                                                        <img src={project_img_url + project.project_image} alt="images" />
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <div className="title"><a href={"/"+project.project_url} className="link" target="_blank">{project.project_name}</a> </div>
                                                                                        <div className="text-date">{project.project_address}</div>
                                                                                        <div className="text-1 fw-7">{"₹"+project.project_price_start} - {"₹"+project.project_price_end}</div> 
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <span>{date1}</span>
                                                                            </td>
                                                                            <td>
                                                                                <ul className="list-action">
                                                                                    <li><a className="remove-file item" onClick={(e) => addtofav(project) }><i className="icon icon-trash"></i>Remove</a></li>
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                            })
                                                        )}
                                                    </>
                                                ):(
                                                    <><tr>
                                                        <td colSpan={3}>No Favorites Found!</td>    
                                                    </tr></>
                                                )}
                                                
                                            </tbody>
                                        </table>


                                    </InfiniteScroll>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
    </>)
}

export default FavouritesProjects