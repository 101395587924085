import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices";
import InfiniteScroll from "react-infinite-scroll-component";

const MyProperties=()=>{

    const didMountRef = useRef(true)  
    const navigate = useNavigate()
  
    const [myproperties, setmyproperties] = useState([])
    const [property_img_url, setproperty_img_url] = useState(null);
    const [hasMore, setHasmore] = useState(false)
    useEffect(() => {
      if (didMountRef.current) {
  
        getMyPropertyData()
      }
      didMountRef.current = false
    })
  
  
    const getMyPropertyData = () => {
      let pageNumber = Math.ceil(myproperties.length / 12) + 1;
      ApiService.fetchData('userProperties?page=' + pageNumber).then((res) => {
        if (res.status == 'success') {
          if(res.last_page > 1 && res.last_page != pageNumber){
            setHasmore(true)
          }else{
            setHasmore(false)
          }
          setproperty_img_url(res.property_img_url)
          setmyproperties(res.data.data)
        }
        else if (res.status == 'session_expired' && res.message == 'Session expired') {
          localStorage.removeItem('USER_TOKEN')
          navigate('/')
        }
  
      }).catch((error) => {
  
      })
    };


    return(<>
    <div className="layout-wrap">
        <Header></Header>
        <div className="main-content">
            <div className="main-content-inner">
                <div className="wrapper-content row">
                    <div className="col-xl-12">
                        <div className="widget-box-2 wd-listing">
                            <h6 className="title">My Properties</h6>
                            <div className="wrap-table">
                                <div className="table-responsive">
                                    <InfiniteScroll
                                    dataLength={myproperties.length}
                                    next={getMyPropertyData}
                                    hasMore={hasMore}
                                    loader={<h4>Loading...</h4>}
                                    style={{ overflow: "hidden !important" }}
                                    >
                                        
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Date Published</th>
                                                    <th>Status</th>
                                                    <th>Feature</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {myproperties.length > 0 && (
                                                    myproperties.map((property, key) => {
                                                        let date1 =new Date(property.created_at).toDateString()

                                                        return <tr className="file-delete">
                                                                    <td>
                                                                        <div className="listing-box">
                                                                            <div className="images">
                                                                                <img src={property_img_url + property.property_image} alt="images" />
                                                                            </div>
                                                                            <div className="content">
                                                                                <div className="title"><a href={"property/"+property.property_slug + "/" + property.property_url_id} className="link">{property.property_name}</a> </div>
                                                                                <div className="text-date">{property.property_address}</div>
                                                                                <div className="text-1 fw-7">{"₹"+property.property_expected_price}</div> 
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span>{date1}</span>
                                                                    </td>
                                                                    <td>
                                                                        <div className="status-wrap">
                                                                            {(property.property_status==1)?<a href="#" className="btn-status">Published</a>:<a href="#" className="btn-status">Under Review</a>}
                                                                            
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span>{(property.property_featured == 1)?"Yes":"No"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <ul className="list-action">
                                                                            <li><a className="item"><i className="icon icon-edit"></i>Edit</a></li>
                                                                            <li><a className="item"><i className="icon icon-sold"></i>Sold</a></li>
                                                                            <li><a className="remove-file item"><i className="icon icon-trash"></i>Delete</a></li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                    })
                                                )}
                                                
                                            </tbody>
                                        </table>


                                    </InfiniteScroll>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
    </>)
}

export default MyProperties