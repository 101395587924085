import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import { FreeMode, Navigation, Thumbs, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

const ProjectSlider = ({projectDetail, projectBaseUrl, gallaryImages}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    
    return <div className="flat-slider home-5">
            <div className="wrap-slider-swiper">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    thumbs={{
                        swiper:
                            thumbsSwiper && !thumbsSwiper.destroyed
                                ? thumbsSwiper
                                : null,
                    }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="swiper-container thumbs-swiper-column"
                >
                    {projectDetail.project_image ?
                        <SwiperSlide>
                            <div className="box-img">
                                <img src={projectBaseUrl + projectDetail.project_image} alt="images" />
                            </div>
                        </SwiperSlide> : ""}
                    {gallaryImages.map((value, index) => (
                        <SwiperSlide key={index}>
                            <div className="box-img">
                                <img src={value.pg_image} alt="images" />
                            </div>
                        </SwiperSlide>))}
                </Swiper>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={3}
                    onSwiper={setThumbsSwiper}
                    loop={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="swiper-container thumbs-swiper-column1 swiper-pagination5"
                >
                    {projectDetail.project_image ?
                        <SwiperSlide>
                            <div className="image-detail">
                                <img src={projectBaseUrl + projectDetail.project_image} alt="images" />
                            </div>
                        </SwiperSlide> : ""}
                    {gallaryImages.map((value) => (
                        <SwiperSlide>
                            <div className="image-detail">
                                <img src={value.pg_image} alt="images" />
                            </div>
                        </SwiperSlide>))}
                </Swiper>
            </div>
        </div>
}

export default ProjectSlider;