import React from 'react'

function HomeAboutUs() {
  return (
    <>
        <section class="flat-section flat-banner-about">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <h3>Welcome To The <br/> Proopify</h3>
                        </div>
                        <div class="col-md-7 hover-btn-view">
                            <p class="body-2 text-variant-1">Welcome to Proopify, where we turn houses into homes and dreams into reality. At Proopify, we understand that a home is more than just a physical space; it's a place where memories are created, families grow, and life unfolds. </p>
                            <a href="/about-us" class="btn-view style-1"><span class="text">Learn More</span> <span class="icon icon-arrow-right2"></span> </a>

                        </div>

                    </div>
                    <div class="banner-video">
                        <img src="images/img-video1.jpg" alt="img-video"  style={{width:'100%' }}/>
                        <a href="https://www.youtube.com/watch?v=yYcN8mpVU40" data-fancybox="gallery2" class="btn-video" style={{  top:'60%'}}> <span class="icon icon-play" style={{fontSize:'70px'}}></span></a>
                    </div>
                </div>
            </section>
    </>
  )
}

export default HomeAboutUs