import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import PropertyList from "../PropertyList";
import PropertyDetail from "../PropertyDetail";
import ProjectDetail from "../ProjectDetail";

const PagesMiddleWare=()=>{
    const [showCityComponent , setCityComponent] = useState(false)
    const [citySlug , setCitySlug] = useState('')
    const location = useLocation();

    useEffect(()=>{
        const expectedStart = '/property-in-';
  
        if (location.pathname.startsWith(expectedStart)) {
          const city = location.pathname.substring(expectedStart.length); 
          setCityComponent(true)
          setCitySlug(city)
        } else {
            setCitySlug('')
            setCityComponent(false)
        }
    }, [])
 
    return(<>
    {showCityComponent? <><PropertyList citySlug={citySlug}></PropertyList></>:<> <ProjectDetail></ProjectDetail></>}
    </>)
}

export default PagesMiddleWare