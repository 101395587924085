import { ApiService } from "../../Components/Services/apiservices"
import React from 'react';
import { useState, useEffect, useRef, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import constant from "../../Components/Services/constant";



const HomeFeaturedServices = () => {
    const [featuredservicesdata, setfeaturedservicesdata] = useState([])
    const [imgUrl, setimgUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedservices()
        }
        didMountRef.current = false
    }, [])

    const getfeaturedservices = () => {
        setIsLoading(true)
        ApiService.fetchData('featuredServices ').then((res) => {
            if (res.status == 'success') {
                setimgUrl(res.serviceImagePath)
                setfeaturedservicesdata(res.serviceData)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500)
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }
    const slidercityRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!slidercityRef.current) return;
        slidercityRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!slidercityRef.current) return;
        slidercityRef.current.swiper.slideNext();
    }, [])
    const onhandleClick = (e, item) => {
        e.preventDefault()
        navigate(`/service/${item.service_slug}`)
    }
    return (<>
        {featuredservicesdata && featuredservicesdata.length > 0 ? (

            <section className="flat-section flat-recommended pt-0 wow fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
                <div className="container">
                    <div className="box-title style-2 text-center">
                        <div className="text-subtitle text-primary">Featured Services</div>
                        <h4 className="mt-4">Explored the proopify featured services</h4>
                    </div>
                    <div className="row">
                        <Swiper
                            spaceBetween={15}

                            ref={slidercityRef}
                            className="swiper tf-sw-location "
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                480: {
                                    slidesPerView: 1,
                                },
                                640: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                                1280: {
                                    slidesPerView: 4,
                                },
                            }}
                        >
                            {featuredservicesdata && featuredservicesdata.map((item, index) => {
                                return (
                                    <SwiperSlide>
                                       
                                            <div class="box-service style-2" onClick={(e) => { onhandleClick(e, item) }}>
                                                <div class="icon-box">
                                                    <span class="icon "><img src={item.service_image ? imgUrl + item.service_image : constant.DEFAULT_IMAGE} alt={item.service_name}></img></span>
                                                </div>
                                                <div class="content">
                                                    <h6 class="title">{item.service_name}</h6>
                                                    {item.service_short_desc ? <p class="description">{item.service_short_desc}</p> : ''}
                                                </div>
                                            </div>
                                      
                                    </SwiperSlide>
                                )
                            }
                            )}
                        </Swiper>
                        {featuredservicesdata && featuredservicesdata.length>2 && ( <div class="box-navigation">
                            <div class="navigation swiper-nav-next nav-next-location" onClick={handlePrev}><span class="icon icon-arr-l"></span></div>
                            <div class="navigation swiper-nav-prev nav-prev-location" onClick={handleNext}><span class="icon icon-arr-r"></span></div>
                        </div>)}
                       
                    </div>
                    <div className="text-center">
                        <a href={`/services`} className="tf-btn primary size-1">View All services</a>
                    </div>
                </div>
            </section>
        ) : null}
    </>)
}


export default HomeFeaturedServices