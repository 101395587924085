import { useState } from "react";
import constant from "../../../Components/Services/constant";

const ProjectFloorplan = ({projectDetail, floorplanimgUrl}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? -1 : index); // Toggle the current index or close if it's already open
    };

    return <>
          {projectDetail?.floorplan && projectDetail?.floorplan.length > 0 && (
                <div className="container">
                    <div className="flat-tab-recommended wow fadeInUpSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
                        <ul className="nav-tab-recommended justify-content-center" role="tablist">
                            {projectDetail.floorplan.map((item, index) => (                                
                                <li className="nav-tab-item" role="presentation" key={index}>   
                                    <a href={"#tab" + index} className={`${index === 0 ? 'nav-link-item active' : 'nav-link-item'}`} data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">{item.ppfp_title}</a>
                                </li>
                            ))}
                            
                        </ul>
                        <div className="tab-content">
                            {projectDetail.floorplan.map((item, index) => (
                                <div className={`${index === 0 ? 'tab-pane fade active show' : 'tab-pane fade'}`} id={"tab" + index} role="tabpanel">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                                <img
                                                src={item.ppfp_image ? `${floorplanimgUrl}${item.ppfp_image}` : constant.DEFAULT_IMAGE}
                                                alt="img-floor"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "10px"
                                                  }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
        )}
    </>
}

export default ProjectFloorplan;