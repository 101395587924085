import React, { useContext, useState, useRef, useEffect } from 'react'
import LoginModal from '../Element/Modals/login_modal'
import { useLocation, useNavigate } from 'react-router-dom'
import DataContext from '../Element/context'
import { ApiService } from '../Services/apiservices'
import constant from '../Services/constant'
import HeaderTop from './header_top'


function Header() {

    const [userDetail, setUserdetail] = useState({})
    const session_token = localStorage.getItem('USER_TOKEN')
    const contextValues = useContext(DataContext)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            const session_token= localStorage.getItem('USER_TOKEN')
            if(session_token && session_token!==null){
                getUserdata()
            }
           
        }
        didMountRef.current = false
    }, [])

    const handleLogoutProcess = () => {
        if (window.confirm('Are you sure you want to logout?')) {
            ApiService.fetchData("logout").then((res) => {
                if (res.status === "success") {
                    localStorage.removeItem("USER_TOKEN")
                    navigate("/")
                }
            })

        } else {
            console.log('Logout cancelled');
        }

    }

    const getUserdata = () => {

        ApiService.fetchData('getuserdata').then((res) => {
            if (res.status == 'success') {
                setUserdetail(res.rowUserData)
                contextValues.setUserData(res.rowUserData)

            }
            else if (res.status == 'session_expired' && res.message == 'Session expired') {
                localStorage.removeItem('USER_TOKEN')
                localStorage.removeItem('TEMP_USER_TOKEN')

                navigate('/')
            }

        }).catch((error) => {

        })
    }
    const handleClick = (route ) => {
        // Data you want to pass
        localStorage.setItem('type','property_for' )
        localStorage.removeItem('areaCitySlug')
        window.location.href=route
        // Navigate to another route and pass the state
    };
    const location = useLocation()

    return (
        <>
        <HeaderTop></HeaderTop>
            <header className={`main-header fixed-header ${session_token && session_token !== null ? 'header-dashboard' : ''}`}>
                <div className="header-lower">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-container d-flex justify-content-between align-items-center">

                                <div className="logo-box flex">
                                    <div className="logo"><a href="/"><img src="/images/logo.png" alt="logo" width="174" height="44" /></a></div>
                                </div>
                                <div className="nav-outer">

                                    <nav className="main-menu show navbar-expand-md">
                                        <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                            <ul className="navigation clearfix">
                                                <li className={`home ${location.pathname=='/'? 'current':'' } `}><a href="/">Home</a>
                                                </li>
                                                <li className={` ${location.pathname=='/post-property'? 'current':'' } `}>
                                                    {!session_token && session_token == null ? <>
                                                        <a href="#modalLogin" data-bs-toggle="modal">Sell</a>
                                                    </> : <>
                                                        <a href="/post-property">Sell</a>
                                                    </>}
                                                </li>
                                                <li className={` ${location.pathname=='/property/buy'? 'current':'' } `}><a href="javascript:void(0)" onClick={()=>{handleClick('/property/buy')}}>Buy</a>
                                                </li>
                                                <li className={` ${location.pathname=='/property/rent'? 'current':'' } `}><a href="javascript:void(0)"  onClick={()=>{handleClick('/property/rent')}}>Rent</a>
                                                </li>
                                                <li className={` ${location.pathname=='/projects'? 'current':'' } `}><a href="/projects">Projects</a>
                                                </li>

                                                <li className={` ${location.pathname=='/agent'? 'current':'' } `}><a href="/agent">Agents</a>
                                                </li>
                                                <li className={` ${location.pathname=='/services'? 'current':'' } `}><a href="/services">Services</a>
                                                </li>



                                            </ul>
                                        </div>
                                    </nav>

                                </div>
                                <div className="header-account">
                                    {!session_token && session_token == null ? <>
                                        <div className="register">
                                            <ul className="d-flex">

                                                <li><a href="#modalLogin" data-bs-toggle="modal">Login & Register</a></li>

                                            </ul>
                                        </div>
                                    </> : <>
                                        <a href="#" className="box-avatar dropdown-toggle" data-bs-toggle="dropdown">
                                            <div className="avatar avt-40 round">
                                                <img src={userDetail?.user_logo ? userDetail?.user_logo : '/images/user.png'} alt="avt" />
                                            </div>
                                            <p class="name">{userDetail?.user_name}<span class="icon icon-arr-down"></span></p>

                                        </a>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="/dashboard">Dashboard</a>
                                            {/* <a class="dropdown-item" href="/my-properties">My Properties</a> */}
                                            <a class="dropdown-item" href="/favourite-properties">Favorites Properties</a>
                                            <a class="dropdown-item" href="/favourite-projects">Favorites Projects</a>
                                            <a class="dropdown-item" href="/profile">My Profile</a>
                                            <a class="dropdown-item" href="/post-property">Add Property</a>
                                            <a class="dropdown-item" href="/leads">Leads/Enquiry</a>
                                            <a class="dropdown-item" href="javascript:void(0)" onClick={() => { handleLogoutProcess() }}>Logout</a>
                                        </div>
                                    </>}
                                    <div className="flat-bt-top">
                                        {!session_token && session_token == null ? <>
                                            <a className="tf-btn primary" href="#modalLogin" data-bs-toggle="modal">Submit Property</a>
                                        </> :
                                            <a className="tf-btn primary" href="/post-property" >Submit Property</a>
                                        }
                                    </div>
                                </div>
                                <div className="mobile-nav-toggler mobile-button"><span></span></div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="close-btn"><span className="icon flaticon-cancel-1"></span></div>
                <div className="mobile-menu">
                    <div className="menu-backdrop"></div>
                    <nav className="menu-box">
                        <div className="nav-logo"><a href="/"><img src="/images/logo.png" alt="nav-logo" width="174" height="44" /></a></div>
                        <div className="bottom-canvas">

                            {!session_token && session_token == null ?
                                <div className="login-box">

                                    <a href="#modalLogin" data-bs-toggle="modal">Login/Register</a>
                                </div>

                                : <>
                                    <div className="login-box">

                                        <a href="/dashboard" >Dashboard</a>
                                    </div>

                                </>}


                            <div className="menu-outer"></div>
                            <div className="button-mobi-sell">
                                {!session_token && session_token == null ?
                                    <a className="tf-btn primary" href="#modalLogin" data-bs-toggle="modal">Submit Property</a> :
                                    <a className="tf-btn primary" href="/post-property">Submit Property</a>
                                }

                            </div>
                            <div className="mobi-icon-box">
                                <div className="box d-flex align-items-center">
                                    <span className="icon icon-phone2"></span>
                                    <div>+91 {contextValues.settingData.admin_support_mobile}</div>
                                </div>
                                <div className="box d-flex align-items-center">
                                    <span className="icon icon-mail"></span>
                                    <div>{contextValues.settingData.admin_support_email}</div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>

            <LoginModal />

        </>

    )
}

export default Header