import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HomeSlider from './home_slider'
import PropertyType from './property_type'
import HomeTestimonials from './home_testimonials'
import HomeBlogs from './home_blogs'
import HomeCities from './home_cities'
import FeaturedProperties from './home_featured_properties'
import HomeAboutUs from './home_about_us'
import { useState, useEffect, useRef } from "react"
import { ApiService } from '../../Components/Services/apiservices'
import { useNavigate } from 'react-router-dom'
import PropertyBox from '../../Components/Element/property_box'
import FeaturedProjects from './home_featured_projects'
import FeaturedAgents from './home_featured_agents'
import HomeWhyChoose from './home_why_choose_us'
import FeaturedFaq from './home_featuured_faq'
import HomePropertyBlogs from './home_property_blogs'
import HomeFeaturedServices from './home_featured_services'
import HomeSecondaryFeaturedProperties from './home_featured_secondary_properties'
import SecondaryFeaturedProjects from './home_featured_secondry_projects'



function Home() {
   
    return (
        <>
            <Header />
            <HomeSlider />
            <PropertyType />
            <FeaturedProjects />
            <SecondaryFeaturedProjects></SecondaryFeaturedProjects>
            <FeaturedProperties />
            <HomeSecondaryFeaturedProperties />
            <HomeAboutUs />
            <HomeWhyChoose></HomeWhyChoose>
            <HomeCities></HomeCities>
            <FeaturedFaq></FeaturedFaq>
            <FeaturedAgents></FeaturedAgents>
            <HomeFeaturedServices></HomeFeaturedServices>
            <HomeTestimonials />
            <HomeBlogs />
            <HomePropertyBlogs />
            <Footer />

        </>

    )
}

export default Home