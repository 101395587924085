import { useNavigate } from "react-router-dom"
import DataContext from "../../Components/Element/context"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices"
import React, {useState , useEffect, useRef , useContext} from "react"


const Profile=()=>{
    const [userDetail, setUserdetail] = useState({})
    const session_token = localStorage.getItem('USER_TOKEN')
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const contextValues = useContext(DataContext)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            const session_token= localStorage.getItem('USER_TOKEN')
            if(session_token && session_token!==null){
                getUserdata()
            }
           
        }
        didMountRef.current = false
    }, [])
    const onTodoChange = (e) => {
       const {name, value} = e.target
        setUserdetail((prevState) => ({
          ...prevState,
          [name]: value,
        })); 
    
      };

  

    const getUserdata = () => {

        ApiService.fetchData('getuserdata').then((res) => {
            if (res.status == 'success') {
                setUserdetail(res.rowUserData)
                contextValues.setUserData(res.rowUserData)

            }
            else if (res.status == 'session_expired' && res.message == 'Session expired') {
                localStorage.removeItem('USER_TOKEN')
                navigate('/')
            }

        }).catch((error) => {

        })
    }
    const updateProfile = (e) => {
        let counter = 0;
        const myElements = document.getElementsByClassName('profilerequired');
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter == 0) {
            setIsLoading(true)
            const updatedUserDetail = {
                ...userDetail,
                user_logo: userDetail.user_logo.value || '' // Include user_logo if it's available
            };
            ApiService.postData("updateProfile", updatedUserDetail).then((res) => {
                if (res.status === "success") {
                    setSuccessMessage(res.message)
                    setTimeout(() => {
                        setSuccessMessage('')
                      window.location.reload()
                    }, 1000);
                }
                else if (res.status == 'error' && res.message == 'Session expired') {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        localStorage.removeItem("USER_TOKEN");
                        window.location.href='/';
                    }, 500);
                }
                else {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500);
                }
            }).catch(() => {
                setIsLoading(false)
            });
        }

    }

    const handleImageUpload = (event) => {
        setErrorMessage(''); 
        const file = event.target.files[0];
        if (file && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg")) {
            if (file.size > 2 * 1024 * 1024) {
                setErrorMessage('File size should not exceed 2MB');
                return;
            }
            
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setUserdetail((prevState) => ({
                    ...prevState,
                    ['user_logo']: {type:'base64' , value : base64String},
                }));
            };
            reader.readAsDataURL(file);
        } else {
            setErrorMessage('Please select an image file (jpg, jpeg, png)');
        }
    };
  
    return(<>
    <div className="layout-wrap">
        <Header></Header>
        <div className="main-content">
            <div className="main-content-inner">
                <div className="wrapper-content row">
                    <div className="col-xl-12">
                        <div className="widget-box-2 wd-listing">
                            <h6 className="title">My Profile</h6>
                            {errorMessage && (
                                        <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    )}
                                    {successMessage && (
                                        <div className="alert alert-success" role="alert">
                                            {successMessage}
                                        </div>
                                    )}
                            <div className="box">
                                <h6 className="title">Avatar</h6>
                                <div className="box-agent-avt">
                                    {userDetail?.user_logo && userDetail?.user_logo?.type=='base64' ? <>
                                        <div className="avatar">
                                        <img src={`data:image/jpeg;base64,${userDetail?.user_logo?.value}`} alt="avatar" loading="lazy" width="128" height="128" />
                                    </div>
                                    
                                    </>:<div className="avatar">
                                        <img src={userDetail?.user_logo ?  userDetail?.user_logo :'images/avatar/account.jpg'} alt="avatar" loading="lazy" width="128" height="128" />
                                    </div>}
                                  
                                    <div className="content uploadfile">
                                        <p>Upload a new avatar</p>
                                        <div className="box-ip">
                                            <input type="file"  className="ip-file"  name = 'user_logo' accept="image/png,image/jpeg" onChange={(e)=>{handleImageUpload(e)}}/>
                                        </div>
                                        <p>Max size 2 MB</p>
                                    </div>
                                </div>
                            </div>

                            <h6 className="title">Information</h6>

                            <div class="box box-fieldset">
                                <label for="name">Full name:<span>*</span></label>
                                <input type="text" class="form-control style-1 profilerequired" name='user_name'  value={userDetail.user_name} onChange={(e)=>{onTodoChange(e)}}/>
                            </div>
                            <div class="box box-fieldset">
                                <label for="name">Email:</label>
                                <input type="text" value={userDetail.user_email} disabled class="form-control style-1" />
                            </div>
                            <div class="box box-fieldset">
                                <label for="name">Mobile:</label>
                                <input type="text" value={userDetail.user_mobile} disabled class="form-control style-1" />
                            </div>
                            <div class="box box-fieldset">
                                <label for="name">Designation:<span>*</span></label>
                                <input type="text" class="form-control style-1 profilerequired" name='user_designation' value={userDetail.user_designation} onChange={(e)=>{onTodoChange(e)}}/>
                            </div>
                            <h6 className="title">Social links</h6>

                            <div class="box box-fieldset">
                                <label for="name">Facebook:</label>
                                <input type="text" class="form-control style-1 " name='user_facebook' value={userDetail.user_facebook} onChange={(e)=>{onTodoChange(e)}} />
                            </div>
                            
                            <div class="box box-fieldset">
                                <label for="name">Linkedin:</label>
                                <input type="text" class="form-control style-1" name='user_linkedin' value={userDetail.user_linkedin} onChange={(e)=>{onTodoChange(e)}}/>
                            </div>
                            
                            <div class="box box-fieldset">
                                <label for="name">X (Twitter):</label>
                                <input type="text" class="form-control style-1" name='user_twitter' value={userDetail.user_twitter} onChange={(e)=>{onTodoChange(e)}} />
                            </div>
                            <div class="box box-fieldset">
                                <label for="name">Instagram:</label>
                                <input type="text" class="form-control style-1" name='user_instagram' value={userDetail.user_instagram} onChange={(e)=>{onTodoChange(e)}} />
                            </div>
                            <div class="box box-fieldset">
                                <label for="name">Bio:</label>
                                <textarea type="text" class="form-control style-1"  rows={3} name='user_bio' value={userDetail.user_bio} onChange={(e)=>{onTodoChange(e)}} />
                            </div>

                            <div class="box">
                                <a href="javascript:void(0)" class="tf-btn primary" onClick={(e)=>{updateProfile(e)}}>Update Profile</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
    </>)
}

export default Profile