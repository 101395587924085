
const ProjectVideo = ({ projectDetail }) => {

    let videolink = "";
    let imagelink = "";

    if(projectDetail?.project_video_link && projectDetail?.project_video_link != '' && projectDetail?.project_video_thumbnail != ''){

        videolink = projectDetail?.project_video_link;
        imagelink = projectDetail.project_video_thumbnail;

    }else if(projectDetail?.project_video_link && projectDetail?.project_video_link != '' && projectDetail?.project_video_thumbnail == ''){
        videolink = projectDetail?.project_video_link;
        imagelink = "images/banner/img-video.jpg";
    }else if(projectDetail?.project_video && projectDetail?.project_video != ''){
        videolink = projectDetail?.project_video;
        imagelink = "images/banner/img-video.jpg";
    }

    return <div className="single-property-video">
        {videolink != '' && imagelink != '' && (
            <div className="img-video">
                <img src={imagelink} />
                <a href={videolink} data-fancybox="gallery2" className="btn-video"> <span className="icon icon-play"></span></a>
            </div>
        )}
    </div>
}

export default ProjectVideo;