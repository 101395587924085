import { useLocation, useParams } from "react-router-dom"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { useState, useEffect, useRef, useContext, useCallback } from "react"
import { ApiService } from "../../Components/Services/apiservices"
import PropertyBox from "../../Components/Element/property_box"
import Skeleton from "react-loading-skeleton"
import InfiniteScroll from "react-infinite-scroll-component";
import DataContext from "../../Components/Element/context"



const SearchResult = () => {
    const contextValues= useContext(DataContext)
    const didMountRef = useRef(true);
    const [propertyList, setpropertyList] = useState([])
    const [selectedSlug, setSelectedSlug] = useState({name:'', id:''});
    const [totalLength, setTotalLength] = useState("");
    const [imgUrl, setimgUrl] = useState('')
    const [sort, setsort] = useState('')
    const [isLoading, setisLoading] = useState(false);
    const [hasMore, sethasMore] = useState(false)
    const [currentPage, setcurrentPage] = useState('')
    const [citiesData, setcitiesData] = useState([])
  const [searchquery, setsearchquery] = useState('');
  const [searchqueryfield, setsearchfield] = useState({
    property_for: '1',
    search_keyword: '',
    location: {id:'', name:''},
    property_type: '',
    no_of_bedrooms: '',
    no_of_balconies: '',
    no_of_bathrooms: '',
    property_furnish: ''
  })
    const searchfield = JSON.parse(localStorage.getItem('searchpropertyfield'));
    useEffect(() => {
        if (didMountRef.current) {
            getPropertyList(null , false ,searchfield  )
            getPropertyTypeData()
            if(searchfield){
                setsearchfield({
                    property_for: searchfield?.property_for,
                    search_keyword: searchfield?.search_keyword,
                    location: {id:searchfield?.location.id , name:searchfield.location.name},
                    property_type: searchfield?.property_type,
                    no_of_bedrooms: searchfield?.no_of_bedrooms,
                    no_of_balconies: searchfield?.no_of_balconies,
                    no_of_bathrooms: searchfield?.no_of_bathrooms,
                    property_furnish: searchfield?.property_furnish
                  })

                  if(searchfield.location){
                    setsearchquery(searchfield.location.name)
                  }
            }
        }
        didMountRef.current = false;
    }, []);

    const onChangeCitySerachInput = (value) => {
        setsearchquery(value);
        console.log(value , 'llkk')
        if (value.length > 1) {
          const dataString = { cities_name: value }
          getCityData(dataString)
        } else {
          setcitiesData([]);
        }
      };

      const getCityData = (dataString) => {
        ApiService.postData('getSearchedCityData', dataString)
          .then((res) => {
            if (res.status === 'success') {
              setcitiesData(res.data);
             
            }
          })
          .catch((error) => {
            console.error("Error fetching city data:", error);
          });
      };
      
      const handleCitySelect = (e, selectedcity) => {
        e.preventDefault()

        setsearchfield((prevState) => ({
          ...prevState,
          ['location']: {id: selectedcity.cities_id , name:selectedcity.cities_name},
      }));
        setsearchquery(selectedcity.cities_name)
        setcitiesData([])
    
      }

      const getPropertyTypeData = () => {
        ApiService.fetchData("getallpropertytype").then((res) => {
          if (res.status === "success") {
            contextValues.setPropertyType(res.allTypes)
            if(searchfield?.property_type!==''){
              const matchedType = res.allTypes.find(item => item?.types_id === searchfield?.property_type);
              setsearchfield((prevState) => ({
                ...prevState,
                property_type : matchedType?.types_id,
              }));
              setSelectedSlug({name:matchedType?.types_name , id:matchedType?.types_id})
            }
    
          }
        })
      }


    const getPropertyList = (sort = null , isNewSearch=false , searchfielddata) => {
        setisLoading(true)
        const dataString = {
                property_name : searchfielddata?.search_keyword,
                property_city : searchfielddata?.location?.id,
                property_for : searchfielddata?.property_for,
                property_category : searchfielddata?.property_type,
                property_no_bedroom : searchfielddata?.no_of_bedrooms,
                property_no_bathroom: searchfielddata?.no_of_bathrooms,
                property_no_balconies: searchfielddata?.no_of_balconies,
                property_furnish:searchfielddata?.property_furnish,
                property_sort:sort? sort : ''
        };
        var pageNumber 
        if(sort){
            if(hasMore){
                pageNumber = Math.ceil(propertyList.length / 10) + 1;
            }
            else{
                pageNumber=currentPage
            }
        }
        if(isNewSearch){
            if(hasMore){
                pageNumber = Math.ceil(propertyList.length / 10) + 1;
            }
            else{
                pageNumber=currentPage
            }
        }
        else{
          pageNumber = Math.ceil(propertyList.length / 10) + 1;
        }
        ApiService.postData("getSearchedPropertyData" + "?page=" + pageNumber, dataString).then((res) => {
          
            if (res.status == "success") {
              
                if(isNewSearch){
                   localStorage.removeItem('searchpropertyfield')
                }
                setcurrentPage(res.data.current_page)
                setimgUrl(res.property_img_url)
                if (pageNumber >= res.data.last_page) {
                  sethasMore(false);
              } else {
                  sethasMore(true);
              }
                if(sort!=='' && sort!==null){
                  setpropertyList(res.data.data);
                  setisLoading(false)
                }
               else if (res.data.data.length > 0) {
                  const apires = res.data.data;
                  setpropertyList([])
                  console.log(propertyList)
                  const mergeData = [...propertyList, ...apires];
                  setpropertyList(mergeData);
                  setTotalLength(res.data.total)
                  setisLoading(false)
                } else {
                  setisLoading(false)
                }
              } else {
                setisLoading(false)
              }
        }).catch(() => {
            setisLoading(false)
        });

    }

    const onTodoChange = (name, value) => {

        setsearchfield((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    
      };


const handleClickSearch=(e)=>{
    e.preventDefault()
    setpropertyList([])
    setTimeout(()=>{
      getPropertyList(null , true , searchqueryfield)
    } , 1000)
   
}

    return (<>
        <Header></Header>
        <section className="flat-filter-search-v2">
      <div className="flat-tab flat-tab-form">
        <ul className="nav-tab-form style-3" role="tablist">
          <li className="nav-tab-item" role="presentation">
            <a href="javascript:void(0)" className={`nav-link-item ${searchqueryfield?.property_for == '2' ? 'active' : ''}`} data-bs-toggle="tab" onClick={() => { onTodoChange('property_for', '2') }}>For Rent</a>
          </li>
          <li className="nav-tab-item" role="presentation">
            <a href="#forSale" className={`nav-link-item ${searchqueryfield?.property_for == '1' ? 'active' : searchqueryfield.property_for==''? 'active':''}`} data-bs-toggle="tab" onClick={() => { onTodoChange('property_for', '1') }}>For Sale</a>
          </li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <div className="form-sl">
              <form method="post">
                <div className="wd-find-select">
                  <div className="inner-group">
                    <div className="form-group-1 search-form form-style">
                      <label>Keyword</label>
                      <input type="text" className="form-control" placeholder="Search Keyword."  value={searchqueryfield?.search_keyword} name="seach_keyword" title="Search for" onChange={(e) => { onTodoChange('search_keyword', e.target.value) }} />
                    </div>
                    <div className="form-group-2 form-style">
                      <label>Location</label>
                      <div className="group-ip">
                        <input type="text" className="form-control" placeholder="Search Location" title="Search for" value={searchquery} 
                        onChange={(e) => { onChangeCitySerachInput(e.target.value) }}
                         />
                        <a href="javascript:void(0)" className="icon icon-location"></a>
                        {citiesData && citiesData.length > 0 ? <>
                          <ul className='searchlocationlist' style={{width:'100%'}}>
                            {citiesData.map((item, index) => {
                              return (<>
                                <li key={index} onClick={(e) => { handleCitySelect(e, item) }}>{item.cities_name}</li>
                              </>)
                            })}
                          </ul>
                        </> : ''}
                      </div>
                    </div>
                    {contextValues.propertyType && contextValues.propertyType.length > 0 ? <>
                      <div className="form-group-3 form-style">
                        <label>Type</label>
                        <div className="group-select">
                          <div className="nice-select" tabindex="0"><span className="current">{selectedSlug?.name? selectedSlug?.name:'Select Type'}</span>
                            <ul className="list">
                              <li data-value className={`option ${selectedSlug.name==''?'selected':''}`}>Select Type</li>
                              {contextValues.propertyType.map((item, index) => {
                                return (<>
                                  <li data-value={item.types_id} className={`option ${selectedSlug?.id==item.types_id?'selected':'' }`} key={index} onClick={() => { onTodoChange('property_type', item.types_id) }}>{item.types_name}</li>
                                </>)
                              })}

                            </ul>
                          </div>
                        </div>
                      </div>
                    </> : ''}
                    <div className="form-group-4 box-filter">
                      <a className="filter-advanced pull-right">
                        <span className="icon icon-faders"></span>
                        <span className="text-1">Advanced</span>
                      </a>
                    </div>
                  </div>
                  <button type="submit" className="tf-btn primary" 
                  onClick={(e) => { handleClickSearch(e) }}
                  >Find Properties</button>
                </div>
                <div className="wd-search-form">
                  <div className="grid-2 group-box">
                    <div className="group-select grid-2">
                      <div className="box-select">
                        <label className="title-select text-variant-1">Rooms</label>
                        <div className="nice-select" tabindex="0"><span className="current">{searchqueryfield?.no_of_bedrooms!==''?searchqueryfield?.no_of_bedrooms:'Select no of rooms'}</span>
                          <ul className="list">
                            <li data-value className={`option ${searchqueryfield?.no_of_bedrooms==''?'selected':''}`}>Select no of rooms</li>
                            {[...Array(10)].map((_, index) => {
                              
                              return (<>
                                <li data-value={index + 1} className={`option ${searchqueryfield?.no_of_bedrooms==index+1? 'selected':''}`} onClick={() => { onTodoChange('no_of_bedrooms', index + 1) }}>{index + 1}</li>

                              </>)
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="box-select">
                        <label className="title-select text-variant-1">Bathrooms</label>
                        <div className="nice-select" tabindex="0"><span className="current">{searchqueryfield?.no_of_bathrooms!==''?searchqueryfield?.no_of_bathrooms:'Select no of bathrooms'}</span>
                          <ul className="list">
                            <li data-value className={`option ${searchqueryfield?.no_of_bathrooms==''?'selected':''}`}>Select no of bathrooms</li>
                            {[...Array(10)].map((_, index) => {
                              return (<>
                                <li data-value={index + 1} className={`option ${searchqueryfield?.no_of_bathrooms==index+1? 'selected':''}`} onClick={() => { onTodoChange('no_of_bathrooms', index + 1) }}>{index + 1}</li>

                              </>)
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="group-select grid-2">
                      <div className="box-select">
                        <label className="title-select text-variant-1">Balconies</label>
                        <div className="nice-select" tabindex="0"><span className="current">{searchqueryfield?.no_of_balconies!==''?searchqueryfield?.no_of_balconies:'Select no of balconies'}</span>
                          <ul className="list">
                            <li data-value className={`option ${searchqueryfield?.no_of_balconies==''?'selected':''}`}>Select no of balconies</li>
                            {[...Array(5)].map((_, index) => {
                              return (<>
                                <li data-value={index + 1} className={`option ${searchqueryfield?.no_of_balconies==index+1? 'selected':''}`} onClick={() => { onTodoChange('no_of_balconies', index + 1) }}>{index + 1}</li>

                              </>)
                            })}

                          </ul>
                        </div>
                      </div>
                      <div className="box-select">
                        <label className="title-select fw-5">Furnishing</label>
                        <div className="nice-select" tabindex="0"><span className="current">{searchqueryfield?.property_furnish=='1'?'Furnished':searchqueryfield.property_furnish=='2'?'Semi Furnished':searchqueryfield.property_furnish=='3'?"UnFurnished":'Select'}</span>
                          <ul className="list">
                            <li data-value className={`option ${searchqueryfield?.property_furnish==''?'selected':''}`}>Select </li>
                            <li data-value="1" className={`option ${searchqueryfield?.property_furnish=='1'?'selected':''}`} onClick={() => { onTodoChange('property_furnish', '1') }}>Furnished</li>
                            <li data-value="2" className={`option ${searchqueryfield?.property_furnish=='2'?'selected':''}`} onClick={() => { onTodoChange('property_furnish', '2') }}>Semi Furnished</li>
                            <li data-value="3" className={`option ${searchqueryfield?.property_furnish=='3'?'selected':''}`} onClick={() => { onTodoChange('property_furnish', '3') }}>UnFurnished</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
        <section className="flat-section-v6 flat-recommended flat-sidebar" data-wow-delay=".4s" data-wow-duration="2000ms">
            <div className="container">
                <div className="box-title-listing ">
                    <h5 className="mt-4">Search Listing</h5>
                    <div class="box-filter-tab">
                        <ul class="nav-tab-filter" role="tablist">
                            <li class="nav-tab-item" role="presentation">
                                <a href="#gridLayout" class="nav-link-item active" data-bs-toggle="tab" aria-selected="true" role="tab"><i class="icon icon-grid"></i></a>
                            </li>
                            <li class="nav-tab-item" role="presentation">
                                <a href="#listLayout" class="nav-link-item" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab"><i class="icon icon-list"></i></a>
                            </li>
                        </ul>
                        <div class="nice-select list-sort" tabindex="0"><span class="current">Sort by (Default)</span>
                            <ul class="list">
                                <li data-value="default" class="option selected">Sort by (Default)</li>
                                <li data-value="new" class="option" onClick={() => { getPropertyList('new' , false , searchqueryfield);setsort('new') }}>Newest</li>
                                <li data-value="old" class="option" onClick={() => { getPropertyList('old' , false, searchqueryfield);setsort('old') }}>Oldest</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <InfiniteScroll
                    dataLength={propertyList.length}
                    next={getPropertyList}
                    hasMore={hasMore}
                    // endMessage={<p className="text-center">No more data to load.</p>}
                    style={{ overflow: "hidden !important" }}
                >

                    {isLoading ? <>
                        <div className="row">
                            {[...Array(8)].map((__, index) => {
                                return (<>

                                    <div className="col-xl-3 col-md-6" key={index}>
                                        <div className="homeya-box md" >
                                            <div className="archive-top">
                                                <div className="images-group">
                                                    <div className="images-style" >
                                                        <Skeleton height={'250px'}></Skeleton>
                                                    </div>
                                                    <div className="top">
                                                        <ul className="d-flex gap-8 flex-column">
                                                            <li className=""><Skeleton width={'50px'}></Skeleton></li>

                                                        </ul>
                                                        <ul className="d-flex gap-4">
                                                            <li className="box-icon w-32" >
                                                                <a href="javascript:void(0)" data-bs-toggle="modal"> <Skeleton></Skeleton></a>

                                                            </li>
                                                            <li className="box-icon w-32">
                                                                <Skeleton></Skeleton>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="archive-bottom d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div className="h7 fw-7"><Skeleton width={'80px'}></Skeleton> </div>
                                                    <div className="h7 fw-7 ms-2"><Skeleton width={'80px'}></Skeleton> </div>
                                                    <div className="h7 fw-7 ms-2"><Skeleton width={'80px'}></Skeleton> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </> : <>
                        {propertyList.length > 0 ? <>
                            <div className="row">
                                {propertyList.map((item, index) => {
                                    return (<>
                                        <div className="col-xl-3 col-md-6" key={index}>
                                            <PropertyBox propertydetail={item} imgUrl={imgUrl}></PropertyBox>
                                        </div>
                                    </>)
                                })}


                            </div>
                        </> : <div className="row">

                            <div className="col-12">
                                <div className="d-flex justify-content-center">
                                    <img src='/images/emptystate.gif' style={{ width: '500px' }}></img>
                                </div>
                                <h6 className="text-center">No Search Result Found</h6>
                            </div>
                        </div>}
                    </>}
                </InfiniteScroll>
            </div>


        </section>
        <Footer></Footer>
    </>)
}

export default SearchResult