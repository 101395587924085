import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PropertyList from "../PropertyList";

function Pages() {
  const navigate = useNavigate()
  const location = useLocation()
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const [pagesData, setPagesData] = useState("")


  useEffect(() => {
    if (didMountRef.current) {
      getPageData()
    }
    didMountRef.current = false;
  }, []);
  const getPageData = () => {
    const dataString = {
      slug: slug

    }
    ApiService.postData('page-content', dataString).then((res) => {
      if (res.status === "success") {
        setPagesData(res.data)
      } else if (res.status === 'error' && res.message === 'Page Not Found') {
        navigate('/error_404')
      }

    });
  };
  return (
    <>
    <Header />
        <section className="flat-title-page">
        <div className="container">
          <h2 className="text-center">{pagesData.page_name}</h2>
          <ul className="breadcrumb">
            <li><a href="/">Home</a></li>
            <li>/ {pagesData.page_name}</li>
          </ul>
        </div>
      </section>
      <section>
        {pagesData.page_content && (
          <div dangerouslySetInnerHTML={{ __html: pagesData.page_content }}></div>
        )}
      </section>
      <Footer />
     
     
    </>
  );
}

export default Pages;
