import { useNavigate, useParams } from "react-router-dom"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices"
import React, { useState, useEffect, useRef, useContext } from "react"
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton"
import PropertyBox from "../../Components/Element/property_box"
const AgentDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [agentDetail, setAgentDetail] = useState({})
    const [agentProperty, setagentProperty] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, sethasMore] = useState(false)
    const [imgUrl, setimgUrl] = useState('')
    const didMountRef = useRef(true)

    useEffect(() => {
        if (didMountRef.current) {
            getAgentdata()

        }
        didMountRef.current = false
    }, [])
    const getAgentdata = () => {
        const dataString = {
            user_unique_id: id
        }
        setIsLoading(true)
        var pageNumber = Math.ceil(agentProperty.length / 10) + 1;
        ApiService.postData('agentDetails' + "?page=" + pageNumber, dataString).then((res) => {
            if (res.status == 'success') {
                setAgentDetail(res.agentData)
                setimgUrl(res.property_img_url)
                if (pageNumber >= res.properties.last_page) {
                    sethasMore(false);
                } else {
                    sethasMore(true);
                }
                if (res.properties && res.properties.data.length > 0) {
                    const apires = res.properties.data;
                    const mergeData = [...agentProperty, ...apires];

                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500)
                    setagentProperty(mergeData)

                }
                else {
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500)

                }

            }
            else if (res.status == 'session_expired' && res.message == 'Session expired') {
                localStorage.removeItem('USER_TOKEN')
                navigate('/')
                setIsLoading(false)
            }

        }).catch((error) => {

        })
    }
    return (<>
        <div className="layout-wrap">
            <Header></Header>
            <div class="main-content">
                <div class="main-content-inner wrap-dashboard-content-2">
                    <div class="button-show-hide show-mb">
                        <span class="body-1">Show Dashboard</span>
                    </div>
                    <div class="widget-box-2">
                        {isLoading ?

                            <div className="row d-flex justify-content-center">
                                <div className="col-md-12">
                                    <div className="homeya-box list-style-1 list-style-2">
                                        <a href="javascript:void(0)" className="images-group">
                                            <div className="images-style">
                                                <Skeleton height={250} width={250} /> 
                                            </div>
                                            <div className="top">
                                                <ul className="agent-social d-flex justify-content-end gap-4">
                                                    <Skeleton circle height={32} width={32} count={3} />
                                                </ul>
                                            </div>
                                        </a>
                                        <div className="content">
                                            <div className="archive-top">
                                                <div className="h7 text-capitalize fw-7">
                                                    <Skeleton width={250} height={25} />
                                                </div>
                                                <div className="desc">
                                                   
                                                    <Skeleton width={100} />
                                                </div>
                                                <div className="desc">
                                                   
                                                    <Skeleton width={100} />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center archive-bottom">
                                                <Skeleton count={3}  width={350} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row  d-flex justify-content-center" >
                                <div class="col-md-12">
                                    <div class="homeya-box list-style-1 list-style-2">
                                        <a href="javascript:void(0)" class="images-group">
                                            <div class="images-style">
                                                <img src={agentDetail.user_logo ? agentDetail.user_logo : '/images/user.png'} alt="image-agent" />
                                            </div>
                                            <div class="top">

                                                <ul className="agent-social d-flex justify-content-end gap-4">
                                                    {agentDetail.user_facebook ? <> <li class="box-icon w-32"><a href={agentDetail.user_facebook} target="_blank"><span className="icon icon-facebook"></span></a></li></> : ''}
                                                    {agentDetail.user_linkedin ? <li class="box-icon w-32"><a href={agentDetail.user_linkedin} target="_blank"><span className="icon icon-linkedin"></span></a></li> : ''}
                                                    {agentDetail.user_twitter ? <li class="box-icon w-32"><a href={agentDetail.user_twitter} target="_blank"><span className="icon icon-twitter"></span></a></li> : ''}
                                                    {agentDetail.user_instagram ? <li class="box-icon w-32"><a href={agentDetail.user_instagram} target="_blank"><span className="icon icon-instagram"></span></a></li> : ''}

                                                </ul>

                                            </div>

                                        </a>
                                        <div class="content">
                                            <div class="archive-top">
                                                <div class="h7 text-capitalize fw-7"><a href="javascript:void(0)" class="link">{agentDetail.user_name}</a></div>
                                                <div class="desc">
                                                    <i class="icon icon-mail"></i>
                                                    {agentDetail.user_email && (
                                                        <a href={`mailto:${agentDetail.user_email}`}>
                                                            <p>{agentDetail.user_email}</p>
                                                        </a>
                                                    )}
                                                </div>
                                                <div class="desc">
                                                    <i class="icon icon-phone"></i>
                                                    <a href={`tel:${agentDetail.user_mobile}`}> <p>{agentDetail.user_mobile}</p></a>
                                                </div>

                                            </div>
                                            {agentDetail.user_bio ? <><div class="d-flex justify-content-between align-items-center archive-bottom">
                                                <div>{agentDetail.user_bio}</div>
                                            </div></> : ''}

                                        </div>
                                    </div>
                                </div>
                            </div>}

                    </div>
                    <section className="flat-section-v6 flat-recommended flat-sidebar" data-wow-delay=".4s" data-wow-duration="2000ms">
                        <div className="container">
                            <div className="box-title text-center wow fadeInUpSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
                                <div className="text-subtitle text-primary">Agent Properties</div>
                                <h4 className="mt-4">Explore the properties listed by our experienced agents.</h4>
                            </div>
                            <InfiniteScroll
                                dataLength={agentProperty.length}
                                next={getAgentdata}
                                hasMore={hasMore}
                                // endMessage={<p>No more data to load.</p>}
                                style={{ overflow: "hidden !important" }}
                            >

                                {isLoading ? <>
                                    <div className="row">
                                        {[...Array(6)].map((__, index) => {
                                            return (<>

                                                <div className="col-xl-4 col-md-6" key={index}>
                                                    <div className="homeya-box md" >
                                                        <div className="archive-top">
                                                            <div className="images-group">
                                                                <div className="images-style" >
                                                                    <Skeleton height={'250px'}></Skeleton>
                                                                </div>
                                                                <div className="top">
                                                                    <ul className="d-flex gap-8 flex-column">
                                                                        <li className=""><Skeleton width={'50px'}></Skeleton></li>

                                                                    </ul>
                                                                    <ul className="d-flex gap-4">
                                                                        <li className="box-icon w-32" >
                                                                            <a href="javascript:void(0)" data-bs-toggle="modal"> <Skeleton></Skeleton></a>

                                                                        </li>
                                                                        <li className="box-icon w-32">
                                                                            <Skeleton></Skeleton>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                            <div className="content">
                                                                <div className="text-1 text-capitalize">
                                                                    <Skeleton width={'200px'}></Skeleton>
                                                                </div>
                                                                <p><Skeleton width={'250px'}></Skeleton></p>
                                                                <div className="desc"><p><Skeleton width={'300px'}></Skeleton></p> </div>


                                                                <ul className="meta-list">
                                                                    <li className="item">

                                                                        <span><Skeleton width={'300px'}></Skeleton></span>
                                                                    </li>
                                                                </ul>


                                                            </div>
                                                        </div>
                                                        <div className="archive-bottom d-flex justify-content-between align-items-center">


                                                            <div className="d-flex align-items-center">
                                                                <div className="h7 fw-7"><Skeleton width={'80px'}></Skeleton> </div>
                                                                <div className="h7 fw-7 ms-2"><Skeleton width={'80px'}></Skeleton> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)
                                        })}
                                    </div>
                                </> : <>
                                    {agentProperty && agentProperty.length > 0 ? <>
                                        <div className="row">
                                            {agentProperty.map((item, index) => {
                                                console.log(agentProperty, imgUrl, 'agentProperty')
                                                return (<>

                                                    <div className="col-xl-4 col-md-6" key={index}>

                                                        <PropertyBox propertydetail={item} imgUrl={imgUrl}></PropertyBox>

                                                    </div>

                                                </>)
                                            })}
                                        </div>
                                    </> : <>
                                        <div className="row">

                                            <div className="col-12">
                                                <div className="d-flex justify-content-center">
                                                    <img src='/images/emptystate.gif' style={{ width: '500px' }}></img>
                                                </div>
                                                <h6 className="text-center">No Property Found for this agent</h6>
                                            </div>
                                        </div>
                                    </>}
                                </>}
                            </InfiniteScroll>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer></Footer>

    </>)
}


export default AgentDetail