import { useState } from "react";
import { validEmail, validNumber } from "../../../Components/Element/Regex";
import { useParams } from "react-router-dom";
import { ApiService } from "../../../Components/Services/apiservices";


const ProjectContact = ({ projectDetail }) => {
    const { slug } = useParams()

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [enquiryUserdetail, setenquiryUserdetail] = useState({
        enquired_user_name: "",
        enquired_user_email: "",
        enquired_user_mobile: "",
        enquired_message: "",
    })

    const onInputChange = (e) => {
        setErrorMessage("");
        setSuccessMessage("");
        const { name, value } = e.target;
        console.log(value, "dddd")
        setenquiryUserdetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== "") {
            e.target.style.border = "";
        }
    };

    const submitEnquire = (e) => {
        e.preventDefault()
        let counter = 0;
        const myElements = document.getElementsByClassName("projectenquirerequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {

                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }

        if (counter == 0) {

            if (!validNumber.test(enquiryUserdetail.enquired_user_mobile)) {
                setErrorMessage("Please Enter Valid Mobile");
                return false;
            }
            if (!validEmail.test(enquiryUserdetail.enquired_user_email)) {
                setErrorMessage("Please Enter Valid Email");
                return false;
            }
            setisLoading(true)
            const dataString = {
                enquiry_name: enquiryUserdetail.enquired_user_name,
                enquiry_email: enquiryUserdetail.enquired_user_email,
                enquiry_mobile: enquiryUserdetail.enquired_user_mobile,
                enquiry_project_id: slug,
                enquired_message: enquiryUserdetail.enquired_message
            };
            console.log(dataString, "dddd")

            ApiService.postData("projectenquiry", dataString).then((res) => {
                if (res.status == "success") {
                    setSuccessMessage(res.message);
                    setenquiryUserdetail({
                        enquired_user_name: "",
                        enquired_user_email: "", 
                        enquired_user_mobile: "", 
                        enquired_message: "",
                    })
                    setTimeout(() => {
                        setSuccessMessage("")
                    }, 5000);
                    setisLoading(false)
                } else {
                    setErrorMessage(res.message);
                    setisLoading(false)
                }
            }).catch(() => {
                setisLoading(false)
            });
        }
    };

    return <div className="widget-box single-property-contact bg-surface">
        <div className="h7 title fw-7">Contact Sellers</div>
        <div className="box-avatar">
            <div className="avatar avt-100 round">
                <img src={projectDetail?.user?.user_logo} alt="avatar" />
            </div>
            <div className="info">
                <div className="text-1 name">{projectDetail?.user?.user_name}</div>
                <span>Please share your contact</span>
            </div>
        </div>
        <form action="#" className="contact-form">
            {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}
            {successMessage && (
                <div className="alert alert-success" role="alert">
                    {successMessage}
                </div>
            )}
            <div className="ip-group">
                <label htmlFor="name" className="label">Full Name:</label>
                <input type="text" className="form-control projectenquirerequired" value={enquiryUserdetail?.enquired_user_name} name="enquired_user_name" onChange={(e) => { onInputChange(e) }} fdprocessedid="fn8tkp" />
            </div>
            <div className="ip-group">
                <label htmlFor="name" className="label">Mobile Number:</label>
                <input type="text" className="form-control projectenquirerequired" value={enquiryUserdetail?.enquired_user_mobile} name="enquired_user_mobile" onChange={(e) => { onInputChange(e) }} fdprocessedid="fn8tkp" />
            </div>
            <div className="ip-group">
                <label htmlFor="email" className="label">Email:</label>
                <input type="email" className="form-control projectenquirerequired" name="enquired_user_email" value={enquiryUserdetail?.enquired_user_email} onChange={(e) => { onInputChange(e) }} fdprocessedid="igf8ik" />
            </div>
            
            <div className="ip-group">
                <button className="tf-btn primary w-100" style={{margin:'0'}} fdprocessedid="hb4e7" onClick={(e) => { submitEnquire(e) }}>
                    {isLoading ? (
                        <img src="/images/loder01.gif" width="60px" height="11px" />
                    ) : (
                        "Send Message"
                    )}

                </button>
            </div>
        </form>
    </div>


}

export default ProjectContact;