
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from "react";
import { ApiService } from '../../Components/Services/apiservices';
import constant from '../../Components/Services/constant';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import moment from 'moment';




function BlogDetail() {
  const [BlogDetail, setBlogDetail] = useState({})
  const [BlogNav, setBlogBlogNav] = useState({
    previousUrl:'',
    previousBlogName:'',
    nextUrl:'',
    nextBlogName:''
  })
  const [BlogImgUrl, setBlogImgUrl] = useState({})
  const [relatedBlog, setrelatedBlog] = useState([])
  const { slug } = useParams()
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        blog_slug: slug,
      };
      ApiService.postData("blog-details", dataString).then(
        (res) => {
          if (res.status === "success") {
            setBlogDetail(res.data);
            setBlogBlogNav({
              previous_blog_url:res.previous_blog_url,
              previousBlogName:res.previousBlogName,
              next_blog_url:res.next_blog_url,
              nextBlogName:res.nextBlogName
            })
            setBlogImgUrl(res.blog_image_path)
            setrelatedBlog(res.relatedBlogsData)

          }
        })
    }
    didMountRef.current = false;
  });
  const protocol = window.location.protocol; // e.g., 'http:' or 'https:'
  const hostname = window.location.hostname; // e.g., 'localhost' or 'www.example.com'
  const port = window.location.port;         // e.g., '3002' or '' for default ports

  // Construct the dynamic URL
  const dynamicUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}/blog/${slug}`;
  const firstTag = BlogDetail?.blog_tags?.[0]?.tag_name ?? ''

  const onPreviousNextClick = (type) => {
    if (type == 'previous') {
      if (BlogNav.previous_blog_url) {
        const dynamicUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}/blog/${BlogNav.previous_blog_url}`;
        window.location.href = dynamicUrl
      }

    }
    else {
      if (BlogNav.next_blog_url) {
        const dynamicUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}/blog/${BlogNav.next_blog_url}`;
        window.location.href = dynamicUrl
      }

    }


  }


  return (
    <>

      <Header />

      <div id="wrapper">
        <div id="pagee" class="clearfix">



          <section class="flat-banner-blog">
            <img src={BlogDetail?.blog_image ? BlogImgUrl + BlogDetail?.blog_image : constant.DEFAULT_IMAGE} alt={BlogDetail?.blog_name} />
          </section>


          <section class="flat-section-v2">
            <div class="container">
              <div class="row">
                <div class="col-lg-10 mx-auto">
                  <div class="flat-blog-detail">
                    {firstTag !== '' && <a href="javascript:void(0)" class="blog-tag primary">{firstTag}</a>}
                    <h3 class="text-capitalize">{BlogDetail?.blog_name}</h3>

                    <div class="mt-12 d-flex align-items-center gap-16">

                      <div class="post-author style-1">

                        <span>{moment(BlogDetail.created_at).format('MMM-DD-YYYY')}</span>
                      </div>
                    </div>

                    <div class="my-40">
                      <p class="body-2 text-black" dangerouslySetInnerHTML={{ __html: BlogDetail.blog_desc }}>
                      </p>
                    </div>
                    {/* Trends */}

                    <div class="my-40 d-flex justify-content-between flex-wrap gap-16">
                      {BlogDetail.blog_tags?.length > 0 ? <>
                        <div class="d-flex flex-wrap align-items-center gap-12">
                          <span class="text-black">Tag:</span>
                          <ul class="d-flex flex-wrap gap-12">
                            {BlogDetail?.blog_tags?.slice(0, 3).map((item, index) => (
                              <li key={index}>
                                <a href="javascript:void(0)" className="blog-tag">
                                  {item.tag_name}
                                </a>
                              </li>
                            ))}

                          </ul>
                        </div>

                      </> : ''}

                      <div class="d-flex flex-wrap align-items-center gap-16">
                        <span class="text-black">Share:</span>
                        <ul class="d-flex flex-wrap gap-12">
                          <li>

                            <FacebookShareButton
                              url={dynamicUrl}
                              hashtag={`#${BlogDetail.blog_name}`}
                              style={{ backgroundColor: '#f7f7f7', padding: '5px', borderRadius: '5px', border: '1px solid #e4e4e4' }}  // Style for visibility
                            >
                              <FacebookIcon size={32} iconFillColor={'black'} bgStyle={{ backgroundColor: '#f7f7f7' }} />
                            </FacebookShareButton>
                          </li>
                          <li>
                            <WhatsappShareButton
                              url={dynamicUrl}
                              title={`${BlogDetail.blog_name}`}
                              style={{ backgroundColor: '#f7f7f7', padding: '5px', borderRadius: '5px', border: '1px solid #e4e4e4' }}  // Style for visibility
                            >
                              <WhatsappIcon size={32} iconFillColor={'black'} />
                            </WhatsappShareButton>
                          </li>
                          <li><TwitterShareButton
                            url={dynamicUrl}
                            hashtag={`#${BlogDetail.blog_name}`}  // Optional hashtag
                            title={`${BlogDetail.blog_name}`}
                            style={{ backgroundColor: '#f7f7f7', padding: '5px', borderRadius: '5px', border: '1px solid #e4e4e4' }}  // Style for visibility
                          >
                            <TwitterIcon size={32} iconFillColor={'black'} />
                          </TwitterShareButton>

                          </li>
                          <li>
                            <TelegramShareButton
                              url={dynamicUrl}  // The URL to be shared
                              title={`${BlogDetail.blog_name}`}
                              style={{ backgroundColor: '#f7f7f7', padding: '5px', borderRadius: '5px', border: '1px solid #e4e4e4' }}  // Style for visibility
                            >
                              <TelegramIcon size={32} iconFillColor={'black'} />
                            </TelegramShareButton>
                          </li>
                          <li>
                            <LinkedinShareButton
                              url={dynamicUrl}
                              title={`${BlogDetail.blog_name}`}
                              style={{ backgroundColor: '#f7f7f7', padding: '5px', borderRadius: '5px', border: '1px solid #e4e4e4' }}  // Style for visibility
                            >
                              <LinkedinIcon size={32} iconFillColor={'black'} />
                            </LinkedinShareButton>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="post-navigation">
                      {BlogNav.previous_blog_url || BlogNav.previousBlogName ? (
                        <div class="previous-post" onClick={() => { onPreviousNextClick('previous') }}>
                          <div class="subtitle">Previous</div>
                          {BlogNav.previousBlogName ? <div class="h7 fw-7 text-black text-capitalize">{BlogNav.previousBlogName}</div> : ''}
                        </div>
                      ):""}
                      {BlogNav.next_blog_url || BlogNav.nextBlogName ? (
                        <div class="next-post" onClick={() => { onPreviousNextClick('next') }}>
                          <div class="subtitle">Next</div>
                          <div class="h7 fw-7 text-black text-capitalize">{BlogNav.nextBlogName}</div>
                        </div>
                      ):''}


                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
          {relatedBlog && relatedBlog.length > 0 ? <>
            <section class="flat-section flat-latest-post">
              <div class="container">
                <div class="box-title-relatest text-center">
                  <div class="text-subheading text-primary">Relatest Post</div>
                  <h5 class="mt-4">News insight</h5>
                </div>
                <div class="row">
                  {relatedBlog.map((item, index) => {
                    return (<>
                      <div class="col-lg-4 col-md-6" key={index}>
                        <a href={`/blog/${item?.blog_slug}`} class="flat-blog-item hover-img">
                          <div class="img-style">
                          <img src={item?.blog_image ? BlogImgUrl + item?.blog_image : constant.DEFAULT_IMAGE} alt={item?.blog_name} />
                            
                            <span class="date-post">{moment(item.created_at).format('MMM-DD-YYYY')}</span>
                          </div>
                          <div class="content-box">
                            {/* <div class="post-author">
                              <span class="fw-7">Esther</span>
                              <span>Furniture</span>
                            </div> */}
                            <h6 class="title">{item.blog_name}</h6>
                            <p class="description" dangerouslySetInnerHTML={{__html:item.blog_desc}}></p>
                          </div>
                        </a>
                      </div>


                    </>)
                  })}


                </div>
              </div>
            </section>



          </> : null}


        </div>

      </div>

      <Footer />
    </>
  )
}

export default BlogDetail