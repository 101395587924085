import { useEffect } from "react";
const ProjectLocation = ({projectDetail}) => {

    const goolemapapi = (location) => {
        function initMap() {
            if (!window.google) {
                console.error('Google Maps API is not available.');
                return;
            }

            var map = new window.google.maps.Map(document.getElementById('map-single'), {
                zoom: 16,
                center: location,
                scrollwheel: false,
            });

            new window.google.maps.Marker({
                position: location,
                map: map,
            });


        }

        if (!window.google) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDuBIgdGc_4dGE2v4gA3DL0efxwHejtY1A&callback=initMap&libraries=places`;
            script.async = true;
            script.defer = true;
            window.initMap = initMap;
            document.head.appendChild(script);
            return () => {
                document.head.removeChild(script);
            };
        } else {

            initMap();

        }
    }


    useEffect(() => {
        var location = { lat: parseFloat(projectDetail.project_lat), lng: parseFloat(projectDetail.project_long) };
        if (projectDetail.project_lat !== NaN && projectDetail.project_long !== NaN) {
            goolemapapi(location)
        }
        else {
            document.getElementById("map-single").style.display = "none";
        }
    })

    return <section className="flat-map mb-0">
            <div className="single-property-element single-property-map">

                <div id="map-single" className="map-single"></div>

            </div>
        </section>
}

export default ProjectLocation;