import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'

function FAQs() {
    const didMountRef = useRef(true)
    const [faqData, setFaqData] = useState([])
    const [pageData, setPageData] = useState({})

    useEffect(() => {
        if (didMountRef.current) {
            getPagesData()
            getFaqData()
        }
        didMountRef.current = false
    })

    const getPagesData = () =>{
        const dataString={
            slug: "faqs",
        }
        ApiService.postData("page-content",dataString).then((res=>{
            if(res.status === "success"){
                setPageData(res.data)
            } 
        }))
    }

    const getFaqData = () => {
        ApiService.fetchData("categoryFaqs").then((res => {
            if (res.status === "success") {
                setFaqData(res.faqCatData)
            }
        }))
    }
    return (
        <>
            <Header />
            <section className="flat-title-page">
                <div className="container">
                    <h2 className="text-center">{pageData.page_name}</h2>

                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li>/ {pageData.page_name}</li>
                    </ul>

                </div>
            </section>
            
            {faqData.length >0 && (
            <section className="flat-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            {faqData.map((category, index) => (
                                <div className="tf-faq">
                                    <h5>{category.fc_name}</h5>
                                    <ul className="box-faq" id={`wrapper-faq-${index}`}>
                                        {category.faqs.map((faq, faqIndex) => (
                                            <li className="faq-item" key={faqIndex}>
                                                <a
                                                    href={`#accordion-faq-${index}-${faqIndex}`}
                                                    className="faq-header collapsed"
                                                    data-bs-toggle="collapse"
                                                    aria-expanded="false"
                                                    aria-controls={`accordion-faq-${index}-${faqIndex}`}
                                                >
                                                    {faq.faq_title}
                                                </a>
                                                <div
                                                    id={`accordion-faq-${index}-${faqIndex}`}
                                                    className={`collapse ${faqIndex === 0 ? 'show' : ''}`}
                                                    data-bs-parent={`#wrapper-faq-${index}`}
                                                >
                                                    <p className="faq-body" dangerouslySetInnerHTML={{__html: faq.faq_description}}>
                                                    </p>
                                                </div>
                                            </li>

                                        ))}
                                    </ul>

                                </div>
                            ))}
{/* 
                            <div className="tf-faq">
                                <h5>Costs and Payments</h5>
                                <ul className="box-faq" id="wrapper-faq-two">
                                    <li className="faq-item">
                                        <a href="#accordion2-faq-one" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion2-faq-one">
                                            How do you calculate fees?
                                        </a>
                                        <div id="accordion2-faq-one" className="collapse" data-bs-parent="#wrapper-faq-two">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>

                                    </li>
                                    <li className="faq-item">
                                        <a href="#accordion2-faq-two" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion2-faq-two">
                                            How do I pay my invoices?
                                        </a>
                                        <div id="accordion2-faq-two" className="collapse" data-bs-parent="#wrapper-faq-two">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="faq-item">
                                        <a href="#accordio2-faq-three" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion2-faq-three">
                                            Are there opportunities for discounts or promotions?
                                        </a>
                                        <div id="accordio2-faq-three" className="collapse" data-bs-parent="#wrapper-faq-two">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="faq-item">
                                        <a href="#accordion2-faq-four" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion2-faq-four">
                                            Are there any hidden fees not displayed in the pricing table?
                                        </a>
                                        <div id="accordion2-faq-four" className="collapse" data-bs-parent="#wrapper-faq-two">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="faq-item">
                                        <a href="#accordion2-faq-five" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion2-faq-five">
                                            What is the refund procedure?
                                        </a>
                                        <div id="accordion2-faq-five" className="collapse" data-bs-parent="#wrapper-faq-two">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="faq-item">
                                        <a href="#accordion2-faq-six" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion2-faq-six">
                                            Is there financial or accounting support?
                                        </a>
                                        <div id="accordion2-faq-six" className="collapse" data-bs-parent="#wrapper-faq-two">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                            <div className="tf-faq">
                                <h5>Safety and Security</h5>
                                <ul className="box-faq" id="wrapper-faq-three">
                                    <li className="faq-item">
                                        <a href="#accordion3-faq-one" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion3-faq-one">
                                            What languages does your service support?
                                        </a>
                                        <div id="accordion3-faq-one" className="collapse" data-bs-parent="#wrapper-faq-three">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>

                                    </li>
                                    <li className="faq-item">
                                        <a href="#accordion3-faq-two" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion3-faq-two">
                                            How do I integrate your service into my system?
                                        </a>
                                        <div id="accordion3-faq-two" className="collapse" data-bs-parent="#wrapper-faq-three">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="faq-item">
                                        <a href="#accordio3-faq-three" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion3-faq-three">
                                            What are the safety features of your system?
                                        </a>
                                        <div id="accordio3-faq-three" className="collapse" data-bs-parent="#wrapper-faq-three">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="faq-item">
                                        <a href="#accordion3-faq-four" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion3-faq-four">
                                            How can I request new features?
                                        </a>
                                        <div id="accordion3-faq-four" className="collapse" data-bs-parent="#wrapper-faq-three">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="faq-item">
                                        <a href="#accordion3-faq-five" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion3-faq-five">
                                            Is my data protected?
                                        </a>
                                        <div id="accordion3-faq-five" className="collapse" data-bs-parent="#wrapper-faq-three">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="faq-item">
                                        <a href="#accordion3-faq-six" className="faq-header collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="accordion3-faq-six">
                                            How do I report a technical issue?
                                        </a>
                                        <div id="accordion3-faq-six" className="collapse" data-bs-parent="#wrapper-faq-three">
                                            <p className="faq-body">
                                                Once your account is set up and you've familiarized yourself with the platform, you are ready to start using our services. Whether it's accessing specific features, making transactions, or utilizing our tools, you'll find everything you need at your fingertips.
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div> */}
                        </div>
                    </div>

                </div>
            </section>
            )}
            <Footer />

        </>

    )
}

export default FAQs