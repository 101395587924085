import { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
function HomeTestimonials() {

    const didMountRef = useRef(true)
    const [testimonialData, setTestimonialData] = useState([])
    const [testimonialImagePath, setTestimonialImagePath] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (didMountRef.current) {
            featuredTestimonial()
        }
        didMountRef.current = false
    })
    const featuredTestimonial = () => {
        setIsLoading(true)
        ApiService.fetchData("featured-testimonial").then((res) => {
            if (res.status === "success") {
                setTestimonialData(res.testimonialData)
                setTestimonialImagePath(res.testimonial_image_path)
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }
    return (
        <>
                {testimonialData && testimonialData.length > 0 ?
        <section className="flat-section-v2 flat-testimonial-v2  wow fadeInUpSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
            <div className="container">
                <div className="box-title text-center position-relative">
                    <div className="text-subtitle text-white">Our Testimonials</div>
                    <h4 className="mt-4 text-white">What’s people say’s</h4>
                </div>
                <Swiper
                    spaceBetween={15}
                    slidesPerView={2}
                    className='swiper tf-sw-testimonial'
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 1,
                        },
                        992: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {testimonialData.map((value, index) => (
                        <SwiperSlide key={index}>
                            <div className="box-tes-item style-1">
                                <ul className="list-star">
                                    {[...Array(5)].map((_, starIndex) => (
                                        <li className={starIndex < value?.testimonial_rating ? "icon icon-star" : "d-icon-star-fill"}>
                                        </li>
                                    ))}                          
                                      </ul>
                                <p className="note body-1" dangerouslySetInnerHTML={{ __html: value.testimonial_desc }}>
                                </p>
                                <div className="box-avt d-flex align-items-center gap-12">
                                    <div className="avatar avt-60 round">
                                        <img src={value.testimonial_image ? testimonialImagePath + value.testimonial_image : constant.DEFAULT_IMAGE} alt="avatar" />
                                    </div>
                                    <div className="info">
                                        <div className="h7 fw-7">{value.testimonial_name}</div>
                                        <p className="text-variant-1 mt-4">{value.testimonial_designation}</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                    )}

                </Swiper>

            </div>
        </section>
           : ""}
        </>
    )
}

export default HomeTestimonials