import { useContext } from "react"
import DataContext from "../Element/context"

const HeaderTop=()=>{
    const contextValues= useContext(DataContext)
    
    return(<>
      <header className={`main-header `}>
                <div className="header-lower">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-container d-flex justify-content-between align-items-center">

                              <div >
                             {contextValues.settingData.admin_support_email ?(<div><i class="ri-mail-line"></i> <a href={`mailto:${contextValues.settingData.admin_support_email}`}><span>{contextValues.settingData.admin_support_email}</span></a></div>):'' } 
                             {contextValues.settingData.admin_support_mobile? (<div> <i class="ri-phone-line"></i><a href={`tel:${contextValues.settingData.admin_support_mobile}`}><span>{contextValues.settingData.admin_support_mobile}</span></a> </div>):''} 
                              </div>
                              
                                <div className="header-account">
                                <ul className="agent-social">
                              {contextValues.settingData.facebook_url ? <a href={contextValues.settingData.facebook_url} target="new"><span className="icon icon-facebook"></span></a>:''}  
                               {contextValues.settingData.linkedin_url ? <a href={contextValues.settingData.linkedin_url} target="new"><span className="icon icon-linkedin"></span></a>:''} 
                                {contextValues.settingData.twitter_url ? <a href={contextValues.settingData.twitter_url}><span className="icon icon-twitter"></span></a>:''}
                               {contextValues.settingData.instagram_url? <a href={contextValues.settingData.instagram_url}> <span className="icon icon-instagram"></span></a>:''}

                                    </ul>
                                </div>
                             

                            </div>
                        </div>
                    </div>
                </div>


              
            </header>
    
    </>)
}


export default HeaderTop