import { useLocation, useParams } from "react-router-dom"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import PropertySearch from "./property_search"
import { useState, useEffect, useRef, useContext, useCallback } from "react"
import { ApiService } from "../../Components/Services/apiservices"
import PropertyBox from "../../Components/Element/property_box"
import Skeleton from "react-loading-skeleton"
import InfiniteScroll from "react-infinite-scroll-component";
import DataContext from "../../Components/Element/context"



const PropertyList = ({ citySlug }) => {
const contextValues= useContext(DataContext)
  const didMountRef = useRef(true);
  const [propertyList, setpropertyList] = useState([])
  const [totalLength, setTotalLength] = useState("");
  const [imgUrl, setimgUrl] = useState('')
  const [sort, setsort] = useState('')
  const [hasMore, sethasMore] = useState(false)
  const [isNewSearch, seisNewsearch] = useState(false)
  const [selectedSlug, setSelectedSlug] = useState({name:'', id:''});
  const [currentPage, setcurrentPage] = useState('')
  const [isLoading, setisLoading] = useState(false);
  const [citiesData, setcitiesData] = useState([])
  const [searchquery, setsearchquery] = useState('');
  const [propertyType, setPropertyType] = useState([])
  const type = localStorage.getItem('type')
  const { slug } = useParams()
  const [searchfield, setsearchfield] = useState({
    property_for: type ? slug == 'buy' ? '1' : 'rent' ? '2' : '' : '1',
    search_keyword: '',
    location: '',
    property_type: '',
    no_of_bedrooms: '',
    no_of_balconies: '',
    no_of_bathrooms: '',
    property_furnish: ''
  })
  const location = useLocation();
const areaCitySlug= JSON.parse(localStorage.getItem('areaCitySlug'))


  useEffect(() => {
    if (didMountRef.current) {
      getPropertyList(null)
      getPropertyData()
     
      if(citySlug && citySlug!==undefined){
        setsearchquery(citySlug)
      }
      else if(areaCitySlug?.area && areaCitySlug?.city){
        setsearchquery(citySlug)
      }
     
    }
    didMountRef.current = false;
  }, []);
  const getPropertyData = () => {

    ApiService.fetchData("getallpropertytype").then((res) => {
      if (res.status === "success") {
        setPropertyType(res.allTypes)
        contextValues.setPropertyType(res.allTypes)
        if(slug && !type){
          const matchedType = res.allTypes.find(item => item.type_slug === slug);
          setsearchfield((prevState) => ({
            ...prevState,
            property_type : matchedType?.types_id,
          }));
          setSelectedSlug({name:matchedType?.types_name , id:matchedType?.types_id})
        }

      }
    })
  }
  
  const getPropertyList = (sort = null) => {
    const type = localStorage.getItem('type')
    setisLoading(true)
    var pageNumber
    if (sort) {
      if (hasMore) {
        pageNumber = Math.ceil(propertyList.length / 10) + 1;
      }
      else {
        pageNumber = currentPage
      }

    } else {
      pageNumber = Math.ceil(propertyList.length / 10) + 1;
    }
    const dataString = {
      property_for: type && !citySlug && !areaCitySlug?.area && !areaCitySlug?.city? slug : '',
      property_sort: sort ? sort : '',
      type_slug: !type && !citySlug && !areaCitySlug?.area && !areaCitySlug?.city ? slug : '',
      property_city: !type && citySlug && !areaCitySlug?.city ? citySlug : !type && citySlug && areaCitySlug?.city ? areaCitySlug?.city :'',
      area_slug:!type && citySlug && areaCitySlug?.area ? areaCitySlug?.area:''
    };
    ApiService.postData("propertiesFor" + "?page=" + pageNumber, dataString).then((res) => {
      if (res.status == "success") {
        setcurrentPage(res.propertylist.current_page)
        setimgUrl(res.property_img_url)
        if (pageNumber >= res.propertylist.last_page) {
          sethasMore(false);
        } else {
          sethasMore(true);
        }
        if (sort !== '' && sort !== null) {
          setpropertyList(res.propertylist.data);
          setisLoading(false)
        }

        else if (res.propertylist.data.length > 0) {
          const apires = res.propertylist.data;
          const mergeData = [...propertyList, ...apires];
          setpropertyList(mergeData);
          setTotalLength(res.propertylist.total)
          setisLoading(false)

        } else {
          setisLoading(false)
        }
      } else {
        setisLoading(false)
      }
    }).catch(() => {
      setisLoading(false)
    });

  }
  const onTodoChange = (name, value) => {

    setsearchfield((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };

  const onChangeCitySerachInput = (value) => {
    setsearchquery(value);
    if (value.length > 1) {
      const dataString = { cities_name: value }
      ApiService.postData('getSearchedCityData', dataString).then((res) => {
        if (res.status === 'success') {
          setcitiesData(res.data);
        }
      }).catch((error) => {
        console.error("Error fetching city data:", error);
      });
    } else {
      setcitiesData([]);
    }
  };
  const handleCitySelect = (e, selectedcity) => {
    e.preventDefault()
    onTodoChange('location', selectedcity.cities_id)
    setsearchquery(selectedcity.cities_name)
    setcitiesData([])

  }
  const onSearchbttnClick = (e) => {
    e.preventDefault()
    localStorage.setItem('searchpropertyfield', JSON.stringify(searchfield));
    getsearchPropertyList(null, true)
  };

  const getsearchPropertyList = (sort = null, isNewSearch = false) => {
    // if (type) {
    seisNewsearch(isNewSearch)
    setisLoading(true)
    const searchfield = JSON.parse(localStorage.getItem('searchpropertyfield'));
    const dataString = {
      property_name: searchfield.search_keyword,
      property_city: searchfield.location,
      property_for: searchfield.property_for,
      property_category: searchfield.property_type,
      property_no_bedroom: searchfield.no_of_bedrooms,
      property_no_bathroom: searchfield.no_of_bathrooms,
      property_no_balconies: searchfield.no_of_balconies,
      property_furnish: searchfield.property_furnish,
      property_sort: sort ? sort : ''

    };
    var pageNumber
    if (sort) {
      if (hasMore) {
        pageNumber = Math.ceil(propertyList.length / 10) + 1;
      }
      else {
        pageNumber = currentPage
      }

    }
    else if (isNewSearch) {
      if (hasMore) {
        pageNumber = Math.ceil(propertyList.length / 10) + 1;
      }
      else {
        pageNumber = currentPage
      }

    }
    else {
      pageNumber = Math.ceil(propertyList.length / 10) + 1;
    }
    ApiService.postData("getSearchedPropertyData" + "?page=" + pageNumber, dataString).then((res) => {
      if (res.status == "success") {
        setcurrentPage(res.data.current_page)
        setimgUrl(res.property_img_url)
        if (pageNumber >= res.data.last_page) {
          sethasMore(false);
        } else {
          sethasMore(true);
        }
        if (sort !== '' && sort !== null) {
          setpropertyList(res.data.data);
          setisLoading(false)
        }
        else if (isNewSearch) {
          setpropertyList(res.data.data);
          setisLoading(false)
        }

        else if (res.data.data.length > 0) {
          const apires = res.data.data;
          const mergeData = [...propertyList, ...apires];
          setpropertyList(mergeData);
          setTotalLength(res.data.total)
          setisLoading(false)

        } else {
          setisLoading(false)
        }
      } else {
        setisLoading(false)
      }
    }).catch(() => {
      setisLoading(false)
    });

  }



  return (<>
    <Header></Header>

    {/* Search code start */}

    <section className="flat-filter-search-v2">
      <div className="flat-tab flat-tab-form">
        <ul className="nav-tab-form style-3" role="tablist">
          <li className="nav-tab-item" role="presentation">
            <a href="javascript:void(0)" className={`nav-link-item ${type && slug == 'rent' ? 'active' : ''}`} data-bs-toggle="tab" onClick={() => { onTodoChange('property_for', '2') }}>For Rent</a>
          </li>
          <li className="nav-tab-item" role="presentation">
            <a href="javascript:void(0)" className={`nav-link-item ${type && slug == 'buy' ? 'active' : !type? 'active':''}`} data-bs-toggle="tab" onClick={() => { onTodoChange('property_for', '1') }}>For Sale</a>
          </li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <div className="form-sl">
              <form method="post">
                <div className="wd-find-select">
                  <div className="inner-group">
                    <div className="form-group-1 search-form form-style">
                      <label>Keyword</label>
                      <input type="text" className="form-control" placeholder="Search Keyword." name="seach_keyword" title="Search for" onChange={(e) => { onTodoChange('search_keyword', e.target.value) }} />
                    </div>
                    <div className="form-group-2 form-style">
                      <label>Location</label>
                      <div className="group-ip">
                        <input type="text" className="form-control" placeholder="Search Location" title="Search for" value={searchquery} onChange={(e) => { onChangeCitySerachInput(e.target.value) }} />
                        <a href="javascript:void(0)" className="icon icon-location"></a>
                        {citiesData && citiesData.length > 0 ? <>
                          <ul className='searchlocationlist' style={{width:'100%'}}>
                            {citiesData.map((item, index) => {
                              return (<>
                                <li key={index} onClick={(e) => { handleCitySelect(e, item) }}>{item.cities_name}</li>
                              </>)
                            })}
                          </ul>
                        </> : ''}
                      </div>
                    </div>
                    {contextValues.propertyType && contextValues.propertyType.length > 0 ? <>
                      <div className="form-group-3 form-style">
                        <label>Type</label>
                        <div className="group-select">
                          <div className="nice-select" tabindex="0"><span className="current">{selectedSlug.name? selectedSlug.name:'Select Type'}</span>
                            <ul className="list">
                              <li data-value className={`option ${selectedSlug.name==''?'selected':''}`}>Select Type</li>
                              {contextValues.propertyType.map((item, index) => {
                                return (<>
                                  <li data-value={item.types_id} className={`option ${selectedSlug.id==item.types_id?'selected':'' }`} key={index} onClick={() => { onTodoChange('property_type', item.types_id) }}>{item.types_name}</li>
                                </>)
                              })}

                            </ul>
                          </div>
                        </div>
                      </div>
                    </> : ''}
                    <div className="form-group-4 box-filter">
                      <a className="filter-advanced pull-right">
                        <span className="icon icon-faders"></span>
                        <span className="text-1">Advanced</span>
                      </a>
                    </div>
                  </div>
                  <button type="submit" className="tf-btn primary" onClick={(e) => { onSearchbttnClick(e) }}>Find Properties</button>
                </div>
                <div className="wd-search-form">
                  <div className="grid-2 group-box">
                    <div className="group-select grid-2">
                      <div className="box-select">
                        <label className="title-select text-variant-1">Rooms</label>
                        <div className="nice-select" tabindex="0"><span className="current">Select no of rooms</span>
                          <ul className="list">
                            <li data-value className="option selected">Select no of rooms</li>
                            {[...Array(10)].map((_, index) => {
                              return (<>
                                <li data-value={index + 1} className="option" onClick={() => { onTodoChange('no_of_bedrooms', index + 1) }}>{index + 1}</li>

                              </>)
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="box-select">
                        <label className="title-select text-variant-1">Bathrooms</label>
                        <div className="nice-select" tabindex="0"><span className="current">Select no of bathrooms</span>
                          <ul className="list">
                            <li data-value className="option selected">Select no of bathrooms</li>
                            {[...Array(10)].map((_, index) => {
                              return (<>
                                <li data-value={index + 1} className="option" onClick={() => { onTodoChange('no_of_bathrooms', index + 1) }}>{index + 1}</li>

                              </>)
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="group-select grid-2">
                      <div className="box-select">
                        <label className="title-select text-variant-1">Balconies</label>
                        <div className="nice-select" tabindex="0"><span className="current">Select no of balconies</span>
                          <ul className="list">
                            <li data-value className="option selected">Select no of balconies</li>
                            {[...Array(5)].map((_, index) => {
                              return (<>
                                <li data-value={index + 1} className="option" onClick={() => { onTodoChange('no_of_balconies', index + 1) }}>{index + 1}</li>

                              </>)
                            })}

                          </ul>
                        </div>
                      </div>
                      <div className="box-select">
                        <label className="title-select fw-5">Furnishing</label>
                        <div className="nice-select" tabindex="0"><span className="current">Select</span>
                          <ul className="list">
                            <li data-value className="option selected">Select </li>
                            <li data-value="1" className="option" onClick={() => { onTodoChange('property_furnish', '1') }}>Furnished</li>
                            <li data-value="2" className="option" onClick={() => { onTodoChange('property_furnish', '2') }}>Semi Furnished</li>
                            <li data-value="3" className="option" onClick={() => { onTodoChange('property_furnish', '3') }}>UnFurnished</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Listing Code Start */}
    <section className="flat-section-v6 flat-recommended flat-sidebar" data-wow-delay=".4s" data-wow-duration="2000ms">
      <div className="container">
        <div className="box-title-listing ">
          <h5 className="mt-4">Property Listing</h5>
          <div class="box-filter-tab">
            <ul class="nav-tab-filter" role="tablist">
              <li class="nav-tab-item" role="presentation">
                <a href="#gridLayout" class="nav-link-item active" data-bs-toggle="tab" aria-selected="true" role="tab"><i class="icon icon-grid"></i></a>
              </li>
              <li class="nav-tab-item" role="presentation">
                <a href="#listLayout" class="nav-link-item" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab"><i class="icon icon-list"></i></a>
              </li>
            </ul>
            {/* <div class="nice-select list-page" tabindex="0"><span class="current">12 Per Page</span>
              <ul class="list">
                <li data-value="1" class="option">10 Per Page</li>
                <li data-value="2" class="option">11 Per Page</li>
                <li data-value="3" class="option selected">12 Per Page</li>
              </ul>
            </div> */}
            <div class="nice-select list-sort" tabindex="0"><span class="current">Sort by (Default)</span>
              <ul class="list">
                <li data-value="default" class="option selected">Sort by (Default)</li>
                <li data-value="new" class="option" onClick={() => { getPropertyList('new' , isNewSearch) ; setsort('new') }}>Newest</li>
                <li data-value="old" class="option" onClick={() => { getPropertyList('old' , isNewSearch); setsort('old') }}>Oldest</li>
              </ul>
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={propertyList.length}
          next={isNewSearch ? getsearchPropertyList : getPropertyList}
          hasMore={hasMore}
          // endMessage={<p className="text-center">No more data to load.</p>}
          style={{ overflow: "hidden !important" }}
        >

          {isLoading ? <>
            <div className="row">
              {[...Array(8)].map((__, index) => {
                return (<>

                  <div className="col-xl-3 col-md-6" key={index}>
                    <div className="homeya-box md" >
                      <div className="archive-top">
                        <div className="images-group">
                          <div className="images-style" >
                            <Skeleton height={'250px'}></Skeleton>
                          </div>
                          <div className="top">
                            <ul className="d-flex gap-8 flex-column">
                              <li className=""><Skeleton width={'50px'}></Skeleton></li>

                            </ul>
                            <ul className="d-flex gap-4">
                              <li className="box-icon w-32" >
                                <a href="javascript:void(0)" data-bs-toggle="modal"> <Skeleton></Skeleton></a>

                              </li>
                              <li className="box-icon w-32">
                                <Skeleton></Skeleton>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                      <div className="archive-bottom d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="h7 fw-7"><Skeleton width={'80px'}></Skeleton> </div>
                          <div className="h7 fw-7 ms-2"><Skeleton width={'80px'}></Skeleton> </div>
                          <div className="h7 fw-7 ms-2"><Skeleton width={'80px'}></Skeleton> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>)
              })}
            </div>
          </> : <>
            {propertyList.length > 0 ? <>
              <div className="row">
                {propertyList.map((item, index) => {
                  return (<>
                    <div className="col-xl-3 col-md-6" key={index}>
                      <PropertyBox propertydetail={item} imgUrl={imgUrl}></PropertyBox>
                    </div>
                  </>)
                })}


              </div>
            </> : <div className="row">

              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <img src='/images/emptystate.gif' style={{ width: '500px' }}></img>
                </div>
                <h6 className="text-center">{isNewSearch ? 'No result Found' : 'No Property Found'}</h6>
              </div>
            </div>}
          </>}
        </InfiniteScroll>
      </div>


    </section>
    <Footer></Footer>
  </>)
}

export default PropertyList