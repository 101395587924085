import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices";
import InfiniteScroll from "react-infinite-scroll-component";
const Leads=()=>{

  const didMountRef = useRef(true)  
  const navigate = useNavigate()

  const [myleads, setmyleads] = useState([])
  const [hasMore, sethasmore] = useState(false)
  useEffect(() => {
    if (didMountRef.current) {

      getLeadData()
    }
    didMountRef.current = false
  })


  const getLeadData = () => {
    let pageNumber = Math.ceil(myleads.length / 12) + 1;
    ApiService.fetchData('userPropertyEnquiry?page=' + pageNumber).then((res) => {
      if (res.status == 'success') {
        if(res.last_page > 1 && res.last_page != pageNumber){
            sethasmore(true)
        }else{
            sethasmore(false)
        }
        
        setmyleads(res.data.data)
      }
      else if (res.status == 'session_expired' && res.message == 'Session expired') {
        localStorage.removeItem('USER_TOKEN')
        navigate('/')
      }

    }).catch((error) => {

    })
  };


    return(<>

    <div className="layout-wrap">
        <Header></Header>
        <div className="main-content">
            <div className="main-content-inner">
                <div className="wrapper-content row">
                    <div className="col-xl-12">
                        <div className="widget-box-2 wd-listing">
                            <h6 className="title">Leads</h6>
                            <div className="wrap-table">
                                <div className="table-responsive">
                                    
                                        <InfiniteScroll
                                            dataLength={myleads.length}
                                            next={getLeadData}
                                            hasMore={hasMore}
                                            loader={<h4>Loading...</h4>}
                                            style={{ overflow: "hidden !important" }}
                                            >
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{"width": "1%"}}>#</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Mobile</th>
                                                            <th>Date</th>
                                                            <th>Property</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                {myleads.length > 0 && (
                                                        myleads.map((lead, key) => {
                                                            return <tr key={key}>
                                                                    <td>{key+1}</td>
                                                                    <td>{lead.enquiry_name}</td>
                                                                    <td>{lead.enquiry_email}</td>
                                                                    <td>{lead.enquiry_mobile}</td>
                                                                    <td>{new Date(lead.created_at).toDateString()}</td>
                                                                    <td>
                                                                        <a href={"property/" + lead.property_slug + "/" + lead.enquiry_property_id} target="_blank">
                                                                            {lead.enquiry_property_id}
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                        })
                                                )}
                                                </tbody>
                                                </table>
                                            </InfiniteScroll>
                                        
                                </div>
                                <ul className="wd-navigation d-none">
                                    <li><a href="#" className="nav-item active">1</a></li>
                                    <li><a href="#" className="nav-item">2</a></li>
                                    <li><a href="#" className="nav-item">3</a></li>
                                    <li>
                                    <a href="#" className="nav-item"><i className="icon icon-arr-r"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
    
    </>)
}

export default Leads