
const ProjectUnits = ({ projectDetail, projectBaseUrl }) => {

    return <>
        {projectDetail?.units && projectDetail?.units.length > 0 && (
            <div className="row g-3">
                    {projectDetail.units.map((item, index) => (
                        <div className="col-lg-3" key={index}>
                            <img src={projectBaseUrl + item?.unit_image} />
                            <p>{item.unit_title} {item.unit_type_name}</p>
                            <p>₹{item.unit_price}</p>
                            <p>Built Up Area - {item.unit_area}</p>
                        </div>
                    ))}
            </div>
        )}
    </>
}

export default ProjectUnits;