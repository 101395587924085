import { useNavigate } from "react-router-dom"
import { ApiService } from "../../Components/Services/apiservices"
import React from 'react';
import { useState, useEffect, useRef, useCallback } from "react"
import PropertyBox from "../../Components/Element/property_box";
import ProjectBox from "../../Components/Element/project_box";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';


const FeaturedAgents=()=>{
    const [featuredagents, setfeaturedAgents] = useState([])
    const [imgUrl, setimgUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const didMountRef = useRef(true)
    const navigate= useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedAgents()
        }
        didMountRef.current = false
    }, [])

    const getfeaturedAgents = () => {
        setIsLoading(true)
        ApiService.fetchData('featuredAgents ').then((res) => {
            if (res.status == 'success') {
                setimgUrl(res.agentImagePath)
                setfeaturedAgents(res.agentData)
                setTimeout(() => {
                    setIsLoading(false)
                }, 500)
            }
            else {
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }
    return(<>

    {featuredagents && featuredagents.length>0 ? <>
        <section className="flat-section flat-agents">
                <div className="container">
                    <div className="box-title text-center wow fadeInUpSmall" data-wow-delay=".2s" data-wow-duration="2000ms">
                        <div className="text-subtitle text-primary">Our Teams</div>
                        <h4 className="mt-4">Meet Our Agents</h4>
                    </div>
                    <div className="row wow fadeInUpSmall" data-wow-delay=".4s" data-wow-duration="2000ms">
                        {featuredagents.map((item , index)=>{
                            return(<>
                              <div className="box col-lg-3 col-sm-6" key={index}>
                            <div className="box-agent style-1 hover-img">
                                <a href={`/agent/${item.user_unique_id}`} className="box-img img-style">
                                    <img src={item.user_logo? item.user_logo:'/images/user.png'} alt="image-agent"/>
                                    {item.user_facebook || item.user_twitter || item.user_linkedin ? <>
                                        <ul className="agent-social">
                                            {item.user_facebook ? <>  <li><a href={item.user_facebook} target="_blank"><span className="icon icon-facebook"></span></a></li></>:""}
                                            {item.user_linkedin ? <>  <li><a href={item.user_linkedin} target="_blank"><span className="icon icon-linkedin"></span></a></li></>:""}
                                            {item.user_twitter ? <>  <li><a href={item.user_twitter} target="_blank"><span className="icon icon-twitter"></span></a></li></>:""}
                                            {item.user_instagram ? <>  <li><a href={item.user_twitter} target="_blank"><span className="icon icon-instagram"></span></a></li></>:""}
                                    </ul>
                                    </>:'' }
                                    
                                </a>
                                <a href={`/agent/${item.user_unique_id}`} className="content">
                                    <div className="info">
                                        <h6 className="link">{item.user_name}</h6>
                                       {item.user_designation ? <p className="mt-4 text-variant-1">{item.user_designation}</p>:'' }
                                    </div>
                                   <a href={`tel:${item.user_mobile}`}><span className="icon-phone"></span></a> 
                                </a>
                            </div>
                        </div>
                            </>)
                        })}
                      
                      
                        
                    </div>
                </div>
            </section>
    
    
    
    </>:null}
    
    
    
    
    
    </>)
}


export default FeaturedAgents