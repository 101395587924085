import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
import BlogSidebar from "./blog_sidebar";
function Blog() {
  const { slug } = useParams();
  const { type } = useParams();
  const didMountRef = useRef(true);
  const [pageData, setPageData] = useState({});
  const [pageContent, setPageContent] = useState("");
  const [blogData, setBlogData] = useState([]);
  const [blogImagePath, setBlogImagePath] = useState([]);
  const [blogImageUrl, setBlogImageUrl] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("")
  const [latestBlogData, setLatestBlogData] = useState([]);
  const [latestCategoryData, setLatestCategoryData] = useState([]);
  const [latestBlogImageUrl, setLatestBlogImageUrl] = useState("");
  const [blogListDefaultImage, setBlogListDefaultImage] = useState("");
  const [defaultImage, setDefaultImage] = useState("");
  useEffect(() => {
    if (didMountRef.current) {

      if (type == "category") { 
        if(slug){
          categorywisebloglist(slug);
        }
        
      } else {
        getBlogData();
      }
      latestBlogs();

      const getPageData = {
        slug: "blogs",
      };
      ApiService.postData("page-content", getPageData).then(
        (res) => {
          if (res.status === "success") {
            setPageData(res.data);
            setHeaderImageUrl(res.page_header_image_path)

          }
        })
    }
    didMountRef.current = false;
  });
  const navigate= useNavigate()
  const getBlogData = () => {
    ApiService.fetchData("blog-list").then((res) => {
      if (res.status === "success") {
        setBlogData(res.blogsData);
        setBlogImageUrl(res.blog_url);
      }
    });
  };

  const latestBlogs = () => {
    ApiService.fetchData("blog-list-sidebar-data").then((res) => {
      if (res.status === "success") {
        setLatestCategoryData(res.blogCategories);
        setLatestBlogData(res.latestblogdata);
        setLatestBlogImageUrl(res.blog_url);
        setBlogListDefaultImage(res.default_image_baseurl);
      }
    })
  }

  const categorywisebloglist = (category_slug) => {
    const dataString = {
      category_slug: category_slug,
    };

    ApiService.postData("category-wise-blogs-list", dataString).then((res) => {
      if (res?.status === "success") {
        setBlogData(res.blogsData);
        setBlogImageUrl(res.blog_url);
        setDefaultImage(res.default_image_baseurl);
      }
    }
    );
  };
  return (
    <>
        <Header/>
            <section className="flat-title-page">
                <div className="container">
                    <h2 className="text-center">Latest News</h2>
                   
                    <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li>/ Blog</li>
                    </ul>
                    
                </div>
            </section>

            <section className="flat-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                   
                            <div className="flat-blog-list">
                            {blogData.map((value, index) => (
                                <div className="flat-blog-item" key={index}>
                                    <div className="img-style">
                                    <img src={value.blog_image != null ? blogImageUrl + "/" + value.blog_image : constant.DEFAULT_IMAGE} alt={value.blog_image}/>
                                        <span className="date-post">{moment(value.created_at).format('MMMM D, YYYY')}</span>
                                    </div>
                                    <div className="content-box">
                                        <h5 className="title">{value.blog_name}</h5>
                                        <p className="description body-1">{value.blog_short_description}</p>
                                        <a href={`/blog/${value.blog_slug}`} className="btn-read-more">Read More</a>
                                    </div>
    
                                </div>
                                      ))}
                   
                            </div>  
                        </div>
                        <div className="col-lg-4">
                         <BlogSidebar latestBlogData={latestBlogData} latestCategoryData={latestCategoryData} latestBlogImageUrl={latestBlogImageUrl}/>
                        </div>
                    </div>
                </div>
            </section>

        <Footer/>
    </>

  )
}

export default Blog