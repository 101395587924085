import './App.css';
import 'remixicon/fonts/remixicon.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Containers/Home';
import Pages from './Containers/Pages';
import PostProperty from './Containers/PostProperty';
import BlogDetail from './Containers/BlogDetail';
import Blog from './Containers/Blog';
import PropertyList from './Containers/PropertyList';
import Agent from './Containers/Agent';
import Services from './Containers/Services';
import FAQs from './Containers/FAQ';
import Dashboard from './Containers/Account/dashboard';
import Favourites from './Containers/Account/my_favourites';
import FavouritesProjects from './Containers/Account/my_favourites_projects';
import MyProperties from './Containers/Account/my_properties';
import Profile from './Containers/Account/my_profile';
import ContactUs from './Containers/ContactUs';
import AboutUs from './Containers/AboutUs';
import Leads from './Containers/Account/leads_enquiry';
import ProPD from './Containers/PropertyDetail';
import ProjectDetail from './Containers/ProjectDetail';
import PropertyDetail from './Containers/PropertyDetail';
import ProjectList from './Containers/ProjectsList'
import SearchResult from './Containers/SearchResult';
import PageNotFound from './Containers/Pages/page_not_found';
import PagesMiddleWare from './Containers/Pages/pagesmiddleware';
import AgentDetail from './Containers/Agent/agent-detail';
import ServiceDetail from './Containers/ServiceDetail';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route exact path="/" activeClassName="active" element={<Home />} />
        <Route exact path='/blog' activeClassName="active" element={ <Blog /> }/>
        <Route exact path='/blog/:type/:slug' activeClassName="active" element={ <Blog /> }/>
        <Route exact path='/agent' activeClassName="active" element={ <Agent /> }/>
        <Route exact path='/agent/:id' activeClassName="active" element={ <AgentDetail /> }/>
        <Route exact path='/services' activeClassName="active" element={ <Services /> }/>
        <Route exact path='/service/:slug' activeClassName="active" element={ <ServiceDetail /> }/>
        <Route exact path='/dashboard' activeClassName="active" element={ <Dashboard /> }/>
        <Route exact path='/favourite-properties' activeClassName="active" element={ <Favourites /> }/>
        <Route exact path='/favourite-projects' activeClassName="active" element={ <FavouritesProjects /> }/>
        <Route exact path='/faqs' activeClassName="active" element={ <FAQs /> }/>
        <Route exact path='/contact-us' activeClassName="active" element={ <ContactUs/> }/>
        <Route exact path='/about-us' activeClassName="active" element={ <AboutUs /> }/>
        <Route exact path='/my-properties' activeClassName="active" element={ <MyProperties /> }/>
        <Route exact path='/projects/:slug?' activeClassName="active" element={ <ProjectList /> }/>
        <Route exact path='/leads' activeClassName="active" element={ <Leads /> }/>
        <Route exact path='/profile' activeClassName="active" element={ <Profile /> }/>
        <Route exact path='/blog/:slug' activeClassName="active" element={ <BlogDetail /> }/>
        <Route exact path="/post-property" activeClassName="active" element={<PostProperty />} />
        <Route exact path="/property/:slug/:id" activeClassName="active" element={<PropertyDetail />} />
        <Route exact path="/property/:slug?" activeClassName="active" element={<PropertyList />} />
        <Route exact path="/:slug" activeClassName="active" element={<PagesMiddleWare />} />
        {/* <Route exact path="/property-in/:slug" element={<PropertyList />} /> */}
        <Route exact path='/page/:slug' activeClassName="active" element={<Pages />} />
        <Route exact path='/search-result/:slug?' activeClassName="active" element={<SearchResult />} />
        <Route path="/error_404" activeClassName="active" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
