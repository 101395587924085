import constant from "../../../Components/Services/constant";

const ProjectAmenities = ({ projectDetail, amenityimgurl }) => {

    return <>
        {projectDetail?.selected_amenities && projectDetail?.selected_amenities.length > 0 && (
            <div className="row g-3">
                {projectDetail.selected_amenities.map((item, index) => (
                    <div className="col-lg-2">
                        <div className="amnitiesbox" key={index}>
                            <div className="box-icon w-80 round">
                            <img src={item?.image ? `${amenityimgurl}${item.image}` : constant.DEFAULT_IMAGE}
                                    alt={item?.name || 'Amenity Image'}
                                />
                            </div>
                            <div className="content text-center">
                                <h6>{item?.name}</h6>
                            </div>
                        </div>
                       
                    </div>
                ))}
            </div>
        )}
    </>
}

export default ProjectAmenities;